.table-candidate {
  .cv_table_checkbox:not(.clear-all-checkbox) .form__checkbox-label::before {
    top: -1px;
  }
  .cv_table_checkbox .form__checkbox-label {
    &:before {
      left: -5px;
    }

    svg.checkbox {
      left: -10px;
    }
    svg.clear-all-checkbox {
      left: -13px;
      top: -9px;
    }
  }
}
