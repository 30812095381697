.employees-timesheet-wrapper {
  margin-left: 350px;
  margin-right: 148px;
  width: calc(100% - 496px);

  .table__head {
    border-top: 0;
  }

  .table__head-column {
    height: 34px;
    border-right: 1px solid $border-color;
    position: relative;
    border-top: 1px solid $border-color;
  }

  .table__row {
    position: static;
  }

  .table__data,
  .table__head-column {
    &:first-child() {
      position: absolute;
      left: 0;
      width: 351px;
      z-index: 1;
    }

    &:not(.header_row):last-child {
      position: absolute;
      width: 74px;
      right: 0;
    }

    &:not(.header_row):nth-last-child(2) {
      position: absolute;
      width: 74px;
      right: 74px;
      border-left: 1px solid $border-color;
    }
  }

  .header_row:last-child {
    position: absolute;
    width: 148px;
    right: 0;
    text-align: center;
    border-left: 1px solid $border-color;
    color: $color-dark;
  }

  .table__head-column:not(.header_row):first-child {
    border-top: none;
    height: 66px;
    top: 0;
    padding-top: 30px;
  }

  .table__data-holiday {
    background: #f5f5f5;
  }

  .table__data {
    height: 58px;
    border-right: 1px solid $border-color;
    min-width: 56px;
    padding: 0;
    overflow: hidden;

    .table__data-wrapper {
      width: 100%;
      height: 100%;
      align-content: flex-start;
    }

    .table__data-value {
      padding: 25px 10px 9px 10px;
      vertical-align: baseline;
      text-align: center;
      width: 100%;
      height: 100%;

      &.red {
        color: #ff0000;
      }
    }
  }

  .table__row-title {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;

    span {
      text-overflow: ellipsis;
      display: block;
    }
  }

  .table__row:last-child {
    .first-column,
    .table__data:nth-last-child(2),
    .table__data:last-child {
      border-bottom: 1px solid $border-color;
    }
    &.show-last-devider .first-column {
      border-bottom: 1px solid $table-hierarchical-border-devider;
    }
  }

  .month-year-display {
    position: absolute;
    top: -22px;
    left: 6px;
    width: 60px;
    color: #4a4a4a;
    background-color: #fff;

    &.right {
      left: unset;
      right: 6px;
      text-align: end;
    }
  }

  th:nth-child(3) {
    .month-year-display {
      left: 25px;
    }
  }

  .header_row {
    height: 30px;
  }

  .not-submited {
    color: $color-light-red;
  }
}

.employees-timesheet-hint {
  .hint-item {
    display: block;
  }

  .hint-item-name {
    margin-bottom: 6px;
  }
}
