@mixin placeholder-shrink {
  transform: none;
  @include input-label-shrink--font;
  @include input-label-shrink--offset;
}

.select {
  @extend .form__input-block;

  &:not(.isMulti) input {
    position: absolute;
    max-width: calc(100% - 36px);
  }

  &__placeholder {
    cursor: pointer;
  }

  &__clear-indicator .cross.icons {
    @include size(9px);
  }

  &.time-select {
    max-width: 134px;
    .select__single-value {
      display: none;
    }

    .select__placeholder {
      @include placeholder-shrink;
      z-index: 1;
    }

    &:hover .form__input {
      box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
    } ///

    .form__input-block {
      height: 100%;
    }

    .form__input {
      &:focus {
        background-color: $bg-input;
      }
      ::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    .select__value-container {
      padding: 0 0 4px 0 !important;
    }
  }
  &__control {
    border: none !important;
    cursor: pointer !important;
    box-shadow: none !important;

    &.select__control--menu-is-open {
      .select-arrow {
        transform: rotate(180deg);
      }
      .select__value-container--searchable .select__placeholder {
        @include placeholder-shrink;
      }
    }
  }

  &__placeholder {
    @extend .form__label;
  }

  &__value-container {
    @extend .form__input;
    padding: 22px $input-with-control-pad-right 4px $input-padding-right !important;
    position: unset !important;
    line-height: 14px;

    &--has-value {
      .select__placeholder {
        @include placeholder-shrink;
      }
    }

    &--is-multi {
      height: unset;
      min-height: $height-input;
      padding-right: 50px !important;
    }

    &--with-icon {
      @extend .form__input--with-icon;
      padding-left: $input-with-icon-pad-left !important;

      .select__placeholder {
        @include input-label-icon-shrink;
      }
    }

    &--with-avatar {
      .select__multi-value__label {
        padding-left: 0 !important;

        .table__data-wrapper {
          padding-left: 1px;
        }

        .page__profile-block-avatar {
          margin-right: 4px;
        }

        .custom-value {
          padding-left: 6px;
        }
      }
    }
  }

  &__no-label &__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__value-container-icon {
    @extend .form__input-icon;
  }

  &__single-value {
    position: unset !important;
    transform: none !important;
  }

  &__indicators {
    @include position(absolute, 50% $input-control-right null null);
    transform: translateY(-50%);

    .select-arrow {
      @include size(16px);
    }
  }

  &__multi-value {
    border-radius: 19px !important;
    background-color: darken($bg-input, 10%) !important;
    min-height: 18px;
    align-items: center;

    &__label {
      padding: 0 0 0 7px !important;
    }

    &__remove {
      padding: 1px 1px 1px 8px !important;
      background-color: transparent !important;
      color: inherit !important;
      cursor: pointer;

      .icons {
        @include size(16px);
        fill: $color-70;
      }
    }
  }

  &__menu {
    background: darken($white, 1%) !important;
    @include dropdown-list;
    margin-top: 0 !important;
    padding: 0 !important;
    top: unset !important;
    z-index: 3 !important;

    @at-root .creatable {
      .select__menu {
        display: none;
      }
    }
    padding: 0 4px !important;
    &-list {
      @include scroll-styles;
    }

    &--top {
      transform: translateY(-100%);
    }
    &-portal {
      z-index: 15 !important;
    }

    &.pagination-menu {
      box-shadow: $dropdown-box-shadow;
    }
  }

  &__option {
    @extend .dropdown__list-item;
    margin: 0;
    white-space: unset;
    line-height: unset;
    cursor: pointer !important;
    color: #4a4a4a !important;

    &--is-focused,
    &:active {
      background: #f5f5f5 !important;
    }
    &--is-disabled {
      opacity: 0.5;
      cursor: default !important;
    }
    &--is-selected {
      font-weight: bold;
      color: var(--global-text-color) !important;
      background-color: var(--global-accent-color) !important;
      &:active {
        background-color: #f5f5f5 !important; //
        color: #4a4a4a !important;
      }
    }
  }

  &--is-disabled {
    opacity: 0.7;
  }

  &__create-wrapper {
    display: flex;
    align-items: center;
    color: #4a4a4a;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    & .select__add-icon {
      margin-right: 6px;
    }
  }
}

.skill_group_select {
  max-width: 200px;
  width: 110px;
  margin-bottom: 0;
  vertical-align: center;
  margin-right: 20px;

  input {
    display: none;
  }

  & > div {
    height: 36px;
    background: none;
  }

  svg.icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
  }

  .select__indicators {
    right: 25px;
    display: none;
  }

  .select__single-value {
    font-weight: bold;
    max-width: none;
  }

  .select__value-container {
    padding: 12px 10px 12px 27px !important;
    height: 36px;
    position: relative;

    & > div {
      margin-top: -1px;
    }
  }
}

.skill_group_select_menu.select__menu {
  border-radius: 5px;
  width: 200px;

  .select__menu-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.specializations-select {
  .select__single-value {
    width: calc(100% - 10px);
  }
}

.specializations-value-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: flex-start;

  .label {
    margin-left: 10px;
  }

  .specialization-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.select__value-container,
.select__option:not(.select__option--is-selected) {
  & .related-assessment,
  & .related-event,
  & .related-poll {
    &__date {
      color: #4a4a4a;
      opacity: 0.5;
    }
  }
}

.select,
.select__menu-portal {
  & .related-assessment,
  & .related-event,
  & .related-poll {
    &__name {
      margin-right: 10px;
    }
  }
}

.chip-input-wrapper {
  margin-bottom: 12px;
  position: relative;

  .chip-input-value-wrapper {
    position: relative;

    .select__value-container-icon {
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }

    .select__placeholder {
      z-index: 10;
    }
  }

  &--with-icon {
    .select__value-container {
      @extend .form__input--with-icon;
      padding-left: $input-with-icon-pad-left !important;
    }

    .select__placeholder {
      @include input-label-icon-shrink;
    }
  }

  .select__input {
    display: none;
  }
  .select__control--is-focused,
  .select__value-container--has-value {
    .select__placeholder {
      @include placeholder-shrink;
    }
  }

  &--focus {
    .select__input {
      display: inline-block;
    }
  }

  .chip-input {
    margin-bottom: 0;
  }

  .icons.envelope {
    color: #c1201e;
  }
}

.select__scroll-manager {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
