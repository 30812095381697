.form__radio-input {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;

  & input {
    display: none;
  }

  &-checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #fff;
    box-sizing: content-box;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }

  &:hover input:not(:checked) ~ &-checkmark {
    border-color: rgba($color-dark, 0.7);
  }

  & input:checked ~ &-checkmark {
    border-color: var(--radio-active-color);
  }

  &-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ &-checkmark:after {
    display: block;
  }

  & &-checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--radio-active-color);
  }
}

.modal--event .form__radio-input-repeater {
  width: 150px;
  margin-right: 0 !important;
}
