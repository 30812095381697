.days-used-limit-table {
  table-layout: fixed;

  tbody {
    display: block;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    @include scroll-styles;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .table__row {
    width: calc(100vw - 60px);

    @media (min-width: 1200px) {
      width: calc(100vw - 124px);
    }
  }

  .table__head-column:not(:last-child),
  .table__data:not(:last-child) {
    border-right: 1px solid $border-color;
  }

  .manual-column .table__data-wrapper {
    display: block;
  }

  .left-column {
    padding: 0;
    position: relative;

    .table__data-wrapper {
      display: block;
    }

    .left-days-block {
      width: 100%;

      & > div {
        display: inline-block;
        width: 50%;

        padding-top: 15px;
        padding-bottom: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:first-child:after {
          content: '';
          position: absolute;
          top: 0;
          left: calc(50% - 1px);
          height: 100%;
          width: 1px;
          background: $border-color;
        }
      }
    }
  }

  .no-limit {
    text-align: center;
  }
}

@media (min-width: 1400px) {
  .main--open-menu .days-used-limit-table .table__row {
    width: calc(100vw - 340px);
  }
}

.manual-dropdown {
  .form__input-block {
    height: 36px;
    width: 84px;
    margin-right: 12px;
    margin-bottom: 0;
  }

  .form__input {
    height: 36px;
    padding-top: 6px;
  }

  .button--modal {
    width: 55px;
    font-size: 14px;
    border-radius: 4px;
  }

  &.dropdown__list {
    width: 200px;
    border-radius: 3px;
    padding: 20px 0;
    top: calc(100% + 12px);
    right: -10px;
  }

  .dropdown__list-item {
    cursor: auto;

    &:hover {
      background: $white;
    }
  }
}

.manual-dropdown .dropdown__button,
.manual-limit {
  color: red;
}

.dropdown__list-item__wrapper .form {
  flex-direction: row;
}
