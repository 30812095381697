.notification-template-modal {
  .modal__body {
    width: 918px;
  }

  .notification-template-wrapper {
    display: flex;
  }
  .notification-template-form,
  .notification-template-variables {
    width: 50%;
  }

  .notification-template-variables {
    padding-left: 31px;
  }

  .variables-title {
    font-weight: 700;
    color: #4a4a4a50;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .variables-note {
    margin-bottom: 20px;
  }

  .variables-item {
    color: #828282;
    font-weight: 500;
    margin-bottom: 8px;
    cursor: pointer;
    &:hover {
      color: #c7c6c6;

      span {
        color: #828282;
        opacity: 100%;
        font-weight: 500;
      }
    }

    &.inline {
      display: inline-block;
    }

    .inline-last {
      margin-left: 5px;
      margin-right: 100px;
    }
  }

  .quill {
    max-height: 60vh;
    .ql-editor {
      min-height: 150px;
    }

    .ql-tooltip {
      transform: none !important;
    }
  }

  .new-modal .quill {
    max-height: 55 vh;
  }
}
