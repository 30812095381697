.modal__office-info {
  width: 100%;
  padding-right: 27px;
}

.modal__office-address,
.modal__office-work-time,
.modal__office-contact,
.modal__office-contact-email,
.modal__office-contact-tel {
  line-height: 18px;
}

.modal__office-address,
.modal__office-contact-name {
  font-weight: bold;
}

.modal__office-address {
  margin-bottom: 10px;
}

.modal__office-work-time {
  display: flex;
  margin-bottom: 30px;
}

.modal__office-work-days {
  margin-right: 21px;
}

.modal__office-contacts-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.modal__office-contact {
  width: calc(50% - 5px);
  min-height: 148px;
  border: $table-border;
  border-radius: $border-radius;
  margin-bottom: 10px;
  padding: 15px;

  &:nth-child(odd) {
    margin-right: 10px;
  }

  a {
    font-family: $main-font;
    font-size: $main-font-size;
    color: $color-dark;
  }
}

.modal__office-contact-name {
  margin-bottom: 3px;
}

.modal__office-contact-email,
.modal__office-contact-tels {
  display: block;
  margin-bottom: 12px;
}
