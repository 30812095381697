.polls {
  display: flex;
  flex-direction: column;

  &__section {
    display: flex;
    position: relative;

    &--headers {
      display: flex;
      justify-content: space-between;
    }

    &--avatar.page__profile-block-avatar {
      position: absolute;
      top: -10px;
      left: -9px;
      z-index: 1;
    }

    &--description {
      margin: 0px 10px;
      line-height: 1.3;
      overflow: hidden;
      text-overflow: ellipsis;
      height: min-content;
      font-size: 14px;
    }

    &--question {
      background-color: $color-poll-question;
      border-radius: 10px;
      padding: 24px 12px;
      margin: 10px 10px 10px 24px;
      box-sizing: border-box;
      min-width: 25%;
      height: min-content;
      width: 32em;
      position: relative;

      &_full {
        width: min-content;
      }
      &_one {
        min-width: calc(50% - 10px);
        width: 25%;
        margin-left: 0;
      }
      &_two {
        width: 40%;
      }
      &_three {
        width: 25%;
      }

      &-wrapper {
        position: sticky;
        min-width: 280px;
        max-width: 280px;
        height: 100%;
        left: 0;
        z-index: 8;
        background: white;
        pointer-events: none;

        .event-poll__content-header--responder {
          margin-left: 14px;
        }
      }
    }

    &--edit {
      height: min-content;
    }

    &--answer {
      padding: 24px 12px;
      margin: 10px;
      box-sizing: border-box;
      min-width: 31%;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $global-color;
        border-radius: 10px;
        opacity: 0.1;
      }

      &:first-child {
        margin-left: 12px;
      }

      .form__input-block-wrapper {
        margin: 0 10px;
      }

      .form__input-block:last-child {
        margin-bottom: 0;
      }

      &_one {
        width: 32em;
        min-width: calc(50% - 10px);
      }
      &_two {
        width: 32em;
        min-width: 45%;
      }
      &_three {
        width: 32em;
        min-width: 25%;
      }
      .form__input,
      .form__textarea,
      .textarea-wrapper-block,
      .form__label,
      .form__textarea:focus + .form__label {
        background-color: $white;
      }

      .number-input .form__label {
        background-color: transparent;
      }
    }
  }
}

.pollInput {
  border: 1px solid #fff;
}

.buttonsSection {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -12px;

  .button:last-child {
    margin-left: 10px;
  }

  &.bottom {
    border-top: 1px solid $border-color;
    margin-top: 15px;
    padding-top: 26px;
    margin-bottom: 60px;
  }
}

.poll__user__status {
  display: flex;
  flex-direction: column;
}

.answer {
  display: flex;
  position: relative;
  overflow-x: auto;
  box-sizing: border-box;
}

.answer::-webkit-scrollbar {
  height: 7px;
}
.answer::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 9em;
  box-shadow: inset 1px 1px 10px $color-poll-answer-scroll;
}
.answer::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}

.marginQuestion {
  margin-bottom: 26px;
}

.switcherCity.page__radios-wrapper .form__radio-label {
  display: inline-block;
  text-align: center;
  position: relative;

  .form__radio-label-text {
    display: inline;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.buttonWidth {
  width: 150px;
}

.respondersLink {
  cursor: pointer;
  line-height: 16px;
  font-weight: bold;
  color: $global-color;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.respondersSection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 22%;
  overflow-x: auto;
  box-sizing: border-box;
  width: 100%;
  &__item {
    width: 100px;
  }
}

.userView {
  width: 32em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 13px;
  margin: 13px 13px;
  box-sizing: border-box;
  min-width: 25%;
}

.anwerView {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  margin-left: 26%;
  &__one {
    margin-left: 50%;
  }
  &__two {
    margin-left: 32%;
  }
}

.anwerView::-webkit-scrollbar {
  height: 7px;
}
.anwerView::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 9em;
  box-shadow: inset 1px 1px 10px $color-poll-answer-scroll;
}
.anwerView::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}

.not-answered {
  color: $global-color;
  margin: 0 10px;
}

.officeList {
  margin: 0px 2px 0px 5px;
}

.allResponders {
  margin-left: 5px;
}

.noquestions {
  margin: 0 auto;
  margin-top: 10px;
  font-size: $middle-font-size;
  line-height: 19px;
}

.poll-control-dropdown .dropdown__list {
  max-height: none;
}

.poll-responders .table__head-column:first-child {
  width: 70%;
}

.export-poll-modal {
  .page__info-block {
    width: 100%;
  }
}

.page__my-polls-content {
  padding-left: 10px;
}

.poll-control-dropdown,
.poll-table-dropdown {
  .dropdown__list {
    max-height: none;
  }

  .dropdown__list-item__button {
    &:not(.checked).status {
      padding-left: 24px;
    }
  }
}
