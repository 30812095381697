@mixin container {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  box-sizing: border-box;
}

@mixin text-styles-white {
  font-family: $main-font;
  font-size: $main-font-size;
  color: $main-bg;
}
@mixin text-styles {
  font-family: $main-font;
  font-size: $main-font-size;
  color: $color-dark;
}

@mixin input-file-upload-btn {
  @include text-styles;
  padding: 4px 9px;
  background: $main-bg;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid $border-setting-color;
  border-radius: $border-radius-input;
}

@mixin input-styles {
  border-radius: $border-radius-input;
  background-color: $bg-input;
  transition: 0.15s;

  &:focus {
    background-color: $bg-input-focused;
  }

  &--white {
    background: $bg-input-white;
    border: $white-input-border;

    &:focus {
      background-color: $bg-input-white-focused;
    }

    & + .form__label {
      color: rgba($color-dark, 0.6);
    }
  }
}

@mixin input-label-shrink--font {
  font-size: $small-font-size;
  line-height: $small-font-size;
}

@mixin input-label-shrink--offset {
  top: 8px;
}

@mixin input-label-icon-shrink {
  left: $input-with-icon-pad-left;
  padding-left: 0;
}

@mixin icon-center-x {
  left: 50%;
  transform: translateX(-50%);
}

@mixin icon-center-y {
  top: 50%;
  transform: translateY(-50%);
}

@mixin different-values-of-width {
  &--half {
    width: $width-input-half;
  }

  &--third {
    width: $width-input-third;
  }

  &--fourth {
    width: $width-input-fourth;
  }

  &--sixth {
    width: $width-input-sixth;
  }

  &--two-third {
    width: $width-input-two-third;
  }

  &--five-sixth {
    width: $width-input-five-sixth;
  }

  &--near-third {
    width: $width-input-near-third;
  }
}

@mixin avatar-styles {
  object-fit: cover;
  border-radius: 50%;
}

@mixin avatar-without-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-input;
  font-family: $main-font;
  font-size: 9px;
  font-weight: bold;
  color: rgba($color-dark, 0.5);
  text-transform: uppercase;
}

@function is-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin dropdown-list($important: false) {
  flex-direction: column;
  align-items: flex-start;
  background: $main-bg;
  border-radius: 4px;
  box-shadow: $dropdown-box-shadow is-important($important);
  padding: 4px 0;
}

@mixin select-head {
  background: $bg-input;
  border-radius: $border-radius-smaller;
  padding: 10px 30px 10px 14px;
  cursor: pointer;
  position: relative;
  @include text-styles;
  font-weight: normal;
  line-height: 16px;
  white-space: nowrap;
}

@mixin page-title {
  font-family: $title-font;
  font-size: 28px;
  line-height: 33px;
  font-weight: bold;
  color: $color-dark;
}

@mixin event-colors {
  &--sick {
    background-color: rgba($color-sick, 0.06);
    color: $color-sick;
  }

  &--vacation {
    background-color: rgba($color-vacation, 0.06);
    color: $color-vacation;
  }

  &--day-off {
    background-color: rgba($color-day-off, 0.06);
    color: $color-day-off;
  }

  &--trip {
    background-color: rgba($color-trip, 0.06);
    color: $color-trip;
  }
}

@mixin scroll-styles {
  scrollbar-color: $scroll-color transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: $scroll-width;
    width: $scroll-width;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scroll-color;
    border-radius: 2.5px;
  }
}

@mixin page-title {
  font-family: $title-font;
  font-size: 28px;
  line-height: 33px;
  font-weight: bold;
  color: $color-dark;
}

@mixin page-radios-wrapper {
  width: 212px;
  margin-bottom: 16px;
}

@mixin info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 34px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

@mixin info-name {
  font-family: $main-font;
  font-size: $small-font-size;
  color: $color-dark;
  line-height: 12px;
  opacity: 0.6;
  margin-bottom: 2px;
}

@mixin info-value {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: $main-font;
  font-size: $main-font-size;
  line-height: 18px;
  color: $color-dark;
  white-space: pre-wrap;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin info-value-hr-avatar {
  width: 16px;
  height: 16px;
  @include avatar-styles;
  margin-right: 5px;
}

@mixin hierarchical-table-border {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  top: 0;
  background: $border-color;
}

@mixin border-bottom-for-last-row {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  top: 100%;
  background: $border-color;
}

@mixin page-info-block-before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
}

@mixin open-icon {
  width: 18px;
  height: 18px;
  fill: $color-dark;
  transition: $main-transition;
  opacity: 0.5;
  margin-right: 8px;
  cursor: pointer;
}
@mixin border-top-for-head-cells {
  .table__row:not(:nth-child(1)) .table__head-column:not(.table__head-column--no-border) {
    border-top: $table-border;
  }
}
@mixin border-left-for-cells {
  .table__head-column,
  .table__data {
    border-left: $table-border;

    &:nth-child(1) {
      border-left: unset;
    }
  }
}
@mixin border-right-for-last-cells {
  .table__head-column,
  .table__data {
    &:nth-last-child(1) {
      border-right: $table-border;
    }
  }
}
@mixin fixed-columns {
  &--fixed-left {
    position: absolute;
    z-index: 2;
  }

  &--fixed-right {
    position: absolute;
  }
}
@mixin border-right-for-first-cells {
  .table__data {
    &:nth-child(1) {
      border-right: $table-border;
    }
  }

  .table__head .table__row:nth-child(1) .table__head-column:nth-child(1):not(.table__head-column--fixed-left) {
    border-right: $table-border;
  }
}

@mixin bordered-opener {
  .table__row--opener .table__data {
    border-left: $table-border;

    &:nth-child(1) {
      border-left: unset;
    }
  }
}

@mixin disabled {
  opacity: 0.6;
  pointer-events: none;
}

@mixin popover {
  width: auto;
  background-color: rgba(0, 0, 0, 0.6);
  color: $white;
  box-shadow: $dropdown-box-shadow;
  border-radius: $border-radius-input;
  font-size: 14px;
}
