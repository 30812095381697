input[type='file']::-webkit-file-upload-button {
  @include input-file-upload-btn;
}

input[type='file']::file-selector-button {
  @include input-file-upload-btn;
}

.recognition__file-input {
  padding-top: 22px;
  padding-left: 24px;
  width: 100%;
}

.recognition__icon-reset,
.navigation__icon-reset {
  color: $global-color;
  cursor: pointer;
  font-size: 18px;
}

.navigation__icon-reset {
  font-size: 14px;
}
