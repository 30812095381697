.incomes-expenses-page-header {
  .check_box_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    height: 36px;
    border-radius: 18px;
    cursor: pointer;
    padding: 9px;

    .checkbox-label {
      text-transform: none;
      line-height: 1px;
      font-weight: normal;
    }
  }

  .page__panel-view {
    margin-right: 20px;
  }
}

.incomes-expenses-page-content {
  .tabel-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;

    &.expenses {
      margin-top: 24px;
    }
  }

  .chart-summary-block {
    display: flex;
    justify-content: center;

    div {
      margin-right: 16px;
      font-weight: 600;
      color: $color-comp-lvl-trainee;

      &:first-child {
        color: $color-comp-lvl-middle;
      }

      &:nth-child(3) {
        color: $color-orange;
      }

      &:last-child {
        color: #da5748;
      }
    }
  }

  .tabs__content-work-wrapper.summary {
    height: calc(100vh - 365px);
  }

  .table__no-data {
    padding: 0;
  }
}

.expense-report-table {
  .table__data,
  .table__head-column {
    border-right: 1px solid $border-color;
    text-align: right;

    &:first-child() {
      text-align: left;
    }

    &:last-child {
      border-right: none;
    }
  }

  .table__dropdown-wrapper {
    display: block;
    height: 100%;
  }

  .dropdown__button {
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }

  .dropdown__list {
    border-radius: 8px;

    &.bottom {
      right: -10px;
      top: calc(100% + 10px);
    }

    &.top {
      bottom: calc(100% + 13px);
      right: -10px;
      top: unset;
      transform: none;
    }

    &.left {
      left: -9px;
    }
  }
}

.expense-report-table-wrapper {
  margin-left: 250px;
  margin-right: 310px;
  width: calc((100% - 250px) - 110px);

  .table__head {
    border-top: 0;
  }

  .table__data {
    height: 48px;
    padding: 6px;

    &:not(:first-child) {
      white-space: nowrap;
    }
  }

  .table__head-column {
    height: 30px;

    &:nth-last-child(2) {
      border-right: none;
    }
  }

  .table__row {
    position: static;
  }

  .table__head-column {
    position: relative;
  }

  .expense-report-table .table__data,
  .table__head-column:not(.header_row) {
    &:first-child() {
      position: absolute;
      left: 0;
      padding-top: 17px;
      padding-bottom: 9px;
      width: 251px;
      border-bottom: 1px solid $border-color;
      vertical-align: middle;
      z-index: 1;
    }

    &:last-child():not(:first-child()) {
      position: absolute;
      right: 0;
      padding-bottom: 9px;
      width: 110px;
      border-bottom: 1px solid $border-color;
      border-left: 1px solid $border-color;
      vertical-align: middle;
      font-weight: 700;

      .without-planned {
        padding-top: 10px;
      }
    }

    &:nth-last-child(2) {
      border-right: none;
    }
  }

  .table__head-column:not(.header_row) {
    &:first-child() {
      padding-top: 0;

      .column-name {
        margin-top: -6px;
      }
    }

    &:last-child():not(:first-child()) {
      padding-top: 0;

      .column-name {
        margin-top: -6px;
      }
    }
  }

  .header_row {
    border-bottom: none;
    height: 30px;
    border-top: 1px solid $border-color;
  }

  .employees-column-head {
    position: absolute;
    left: 0;
    padding-top: 12px;
    padding-bottom: 9px;
    width: 251px;
  }

  .total-column-head {
    position: absolute;
    right: 0;
    padding-top: 12px;
    padding-bottom: 9px;
    width: 110px;
    border-left: 1px solid $border-color;
  }

  .table__row-head {
    border-top: none;
  }

  .year-display {
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
  }

  .table__row:last-child .table__data {
    &:first-child {
      color: rgba(74, 74, 74, 0.6);
    }
    font-weight: 700;
  }

  .income-block {
    width: 100%;

    .planed-income {
      color: $color-70;
      opacity: 0.7;
    }
  }
}

.expense-report-table-wrapper .expense-report-table.incomes-report-table.table.table--hierarchical {
  .table__data {
    padding: 6px;
    text-align: right;

    &:last-child:not(:first-child) {
      padding-top: 7px;
      border-bottom: 0px;
    }

    &:first-child {
      padding-top: 0px;
      border-bottom: 0px;
    }
  }

  .table__head-column:not(.header_row) {
    border-top: 1px solid $border-color;
    &:first-child,
    &:last-child {
      border-bottom: 0px;
      border-top: 0px;
    }
  }

  .table__sub-row .table__row-title {
    padding-left: 60px;
  }

  .table__row:last-child,
  .table__row:nth-last-child(2) {
    .table__data {
      &:first-child {
        color: rgba(74, 74, 74, 0.6);

        .table__row-title {
          padding-top: 8px;
        }
      }

      &:last-child {
        padding-top: 12px;
      }

      font-weight: 700;
      height: 40px;
    }
  }

  .table__row:last-child .table__data {
    &:first-child,
    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  .table__row--opener .table__data {
    font-weight: 400;
  }

  .table__row.even {
    &,
    .table__data:first-child,
    .table__data:last-child {
      background-color: #f9f9f9e6;
    }
  }
}
