.infinite-scroll-component {
  @include scroll-styles;
  overflow: hidden !important;
  min-height: 200px;
}

.infinite-scroll-loader {
  width: 100%;
}

.infinite-scroll-wrappper {
  @include scroll-styles;
  min-height: 50px;
  max-height: 830px; //max-content-height of comment - 1030px
  overflow-x: hidden;
  overflow-y: auto;
}
