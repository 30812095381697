.form__checkbox-block {
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  &.label {
    margin-bottom: 10px;
  }

  &--align-right {
    text-align: right;
  }

  &.align-center {
    align-items: center;
  }

  &:hover .form__checkbox-label::before {
    border-color: rgba($color-dark, 0.7);
  }

  .form__checkbox-label svg {
    width: 10px;
    height: 10px;
    left: 3px;
    top: 3px;
    transform: unset;
  }

  &.checkbox-no-label {
    label {
      padding-left: 0;

      &::before {
        transform: translate(-50%, -50%);
      }

      svg {
        left: -4px;
      }
    }
  }
}

.form__checkbox {
  display: none;

  &:checked + .form__checkbox-label .icons.checkbox {
    fill: $global-text-color;
  }

  &:checked + .form__checkbox-label .icons.clear-all-checkbox {
    fill: $global-color;
  }

  & + .form__checkbox-label .icons.checkbox {
    fill: transparent;
  }

  &:checked + .form__checkbox-label::before {
    background: $global-color;
    border-color: transparent;
  }

  &--mixed:checked + .form__checkbox-label::before {
    content: '—';
    color: $main-bg;
    background-image: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:disabled {
    & + .form__checkbox-label {
      cursor: unset;

      &::before {
        border-color: rgba($color-dark, 0.2);
      }
    }

    &:checked + .form__checkbox-label::before {
      background: $global-color;
      opacity: 0.2;
      border-color: transparent;
    }

    &:hover:not(:checked) + .form__checkbox-label::before {
      border-color: rgba($color-dark, 0.2);
    }
  }
}

.form__checkbox-label {
  @include text-styles;
  line-height: 16px;
  padding-left: 26px;
  position: relative;
  cursor: pointer;
  &--bold {
    font-weight: bold;
  }

  &::before {
    content: '';
    @include size(16px);
    @include position(absolute, -1px null null 0);
    border: 2px solid rgba($color-dark, 0.5);
    border-radius: 3px;
  }
}

.form__checkbox-block.clear-all-checkbox .form__checkbox-label {
  &:before {
    border: 2px solid rgba(74, 74, 74, 0.5);
  }
  svg {
    color: rgba(255, 255, 255, 0);
    border-radius: 3px;
    width: 16px;
    height: 16px;
    left: 0px;
  }
}

.clear-all-checkbox .form__checkbox:checked + .form__checkbox-label:before {
  background: $global-text-color;
  border: none;
  width: 10px;
  height: 10px;
}

.lowercase {
  .form__checkbox-label {
    text-transform: none;
  }
}

.content-start.label {
  justify-content: start;
  padding-left: 0;
}

.weight-normal {
  .form__checkbox-label {
    font-weight: 400;
  }
}
