.user_technical_skills_modal {
  .skill_group_name_block {
    display: flex;
    justify-content: space-between;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 22px;
      top: 10px;
      width: calc(100% - 22px);
      height: 3px;
      background: #f5f5f5;
      z-index: 1;
    }
  }

  .tabs__content-item__about-column-title {
    background: #fff;
    z-index: 2;
    padding-right: 10px;
  }

  .form__group-subwrapper {
    margin: 0 22px 24px 0;
  }

  .form__btn-clean-inputs {
    padding-top: 4px;
  }
}
