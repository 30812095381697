.dropdown {
  position: relative;

  &--no-bg {
    .dropdown__button {
      background: transparent;
      width: 18px;
      height: 18px;
      padding: 0;
    }
  }

  &--gray {
    .dropdown__button {
      svg {
        opacity: 0.5;
      }

      &:hover svg {
        opacity: 1;
        fill: $color-dark;
      }
    }
  }

  &--table {
    display: flex;
    justify-content: flex-end;

    .dropdown__list-item {
      padding: 0 14px;
    }
  }

  &--header {
    .dropdown__button {
      background: transparent;
      width: auto;
      height: auto;
    }
  }

  &--select-style {
    .dropdown__button {
      width: auto;
      @include select-head;
    }

    &.dropdown--red {
      .dropdown__button-icon-before {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }

      .dropdown__button {
        font-weight: bold;
        padding-left: 16px;
        padding-right: 38px;
      }
    }

    .dropdown__list-item {
      padding-left: 14px;
      padding-right: 14px;
    }

    .dropdown__list-item__button {
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  &--red {
    .dropdown__button {
      background: $color-red;
      color: $main-bg;

      svg {
        fill: $main-bg;
      }
    }
  }

  &--left {
    .dropdown__list {
      left: 0;
      right: unset;
    }
  }

  &--menu {
    .dropdown__list-item {
      font-weight: normal;
      padding-left: 24px;
      padding-right: 62px;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &--radios {
    .dropdown__list-item__button {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &.open {
    .dropdown__list {
      display: flex;
    }
  }

  &__second-list {
    @include dropdown-list;
    transform: translateX(calc(-100% - 10px));
    display: none;
    position: absolute;
    line-height: 36px;
  }

  &__second-item .dropdown__list-item__wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .bold {
    font-weight: bold;
  }
}

.dropdown__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $bg-input;
  @include size(36px);
  border-radius: 50%;
  cursor: pointer;
  padding: 9px;

  &:hover {
    box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.dropdown__button-main-icon {
  @include size(100%);
  transition: $main-transition;
  color: $color-dark;
}

.dropdown__button-icon-before {
  width: 12px;
  height: 12px;
  fill: $color-dark;
  margin-right: 8px;
}

.dropdown__list {
  @include dropdown-list;
  width: fit-content;
  @include scroll-styles;
  border-radius: 4px;
  height: max-content;
  z-index: 15;

  &.overflow {
    overflow: auto;
  }
}

.dropdown__list-item,
.dropdown__list-item__button {
  width: 100%;
  text-align: left;
  display: block;
  font-family: $main-font;
  font-size: $main-font-size;
  line-height: 36px;
  color: $color-dark;
  white-space: nowrap;
  position: relative;

  &--checked::after {
    content: '\2713';
    position: absolute;
    top: -1px;
    right: 4px;
  }
}

.dropdown__list-item {
  width: calc(100% - 8px);
  margin: 0 4px;
  border-radius: 4px;

  &__wrapper {
    width: 100%;
    padding: 0 12px 0 14px;
  }
  transition: $main-transition;
  cursor: pointer;

  &:not(.dropdown__list-item--user):hover {
    background: #f5f5f5;
    color: #4a4a4a;

    .dropdown__list-item__button {
      color: #4a4a4a;

      .dropdown__list-item__icon {
        fill: #4a4a4a;
      }
    }
  }

  &.line .dropdown__list-item__button {
    border-bottom: 1px solid $color-40;
  }

  &.top-line .dropdown__list-item__button {
    border-top: 1px solid $color-40;
  }

  &--user {
    padding-top: 11px;
    margin-bottom: 13px;
  }

  &--border-under {
    & + .dropdown__list-item {
      padding-top: 4px;
    }

    .dropdown__list-item__button {
      border-bottom: $table-border;
      padding-bottom: 4px;
    }
  }

  &--parent {
    padding-right: 62px;

    &:hover {
      .dropdown__list-item-child {
        display: flex;
      }
    }
  }

  &.second-level {
    &:hover .dropdown__second-list {
      display: block;
      background-color: $white;
    }

    .dropdown__list-item__wrapper {
      display: flex;
      align-items: center;
    }

    .dropdown__list-item-content {
      display: flex;
      align-items: center;
    }

    .filter-nested-arrow {
      display: inline-block;
      fill: $color-70;
      margin-left: 10px;
    }
  }
}

.dropdown__list-item--parent .dropdown__list-item__button {
  padding-left: 0;
  padding-right: 0;
}

.dropdown__list-item__button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &--global-color {
    color: var(--global-color);
  }

  &:disabled {
    color: rgba($color-dark, 0.5);
  }
}

.dropdown__list-item__button--radio__input {
  display: none;
}

.dropdown__list-item__button--radio__label {
  position: relative;

  &::before {
    content: '';
    display: flex;
    align-items: center;
    width: 14px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 12px;
  }
}

.dropdown__list-item--user-name,
.dropdown__list-item--user-email {
  font-family: $main-font;
  font-size: $main-font-size;
  line-height: 16px;
  color: $color-dark;
  letter-spacing: 0.1px;
  white-space: nowrap;
  opacity: 0.6;
}

.dropdown__list-item--user-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.dropdown__list-item--user-email {
  font-weight: normal;
}

.dropdown__list-item__icon {
  @include size(14px);
  fill: $color-dark;
  margin-right: 10px;
}

.dropdown__list-item-child {
  // display: none;
  position: absolute;
  left: calc(100% + 1px);
  top: -10px;
  @include dropdown-list;
}

.dropdown__list-item-child .dropdown__list-item {
  padding-left: 24px;
  padding-right: 24px;
}

.dropdown__event-time {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  right: 6px;
  & .dropdown__button {
    background: none;
  }
  & .dropdown__list {
    &-item {
      min-width: 134px;
    }
  }
}

.dropdown_form {
  .dropdown__button {
    border-radius: 18px !important;
    margin: 0;
    font-weight: normal;
    padding: 10px 14px;

    svg {
      margin: 1px 0 0 12px;
    }
  }
}

.dropdown__list ul {
  &.overflow {
    overflow: auto;
  }

  @include scroll-styles;
}

.filter__menu .filter__option {
  width: calc(100% - 5px);
  margin-right: 1px;
  line-height: 1;
}

.popup-content {
  z-index: 12 !important;

  .select__scroll-manager {
    z-index: -1;
  }
}
