.offices-balance-wrapper {
  margin-left: 250px;

  .table__head {
    border-top: 0;
  }

  .table__head-column {
    border-right: 1px solid $border-color;
    position: relative;
    border-top: 1px solid $border-color;

    &:not(:first-child) {
      text-align: center;
    }

    &:not(.header_row) {
      &:first-child {
        border-top: none;
        height: 66px;
        top: 0;
        padding-top: 22px;
      }
    }
  }

  .table__row {
    position: static;
  }

  .table__data,
  .table__head-column,
  .header_row {
    &:first-child() {
      position: absolute;
      left: 0;
      width: 250px;
      z-index: 1;

      &:before {
        width: 250px;
      }
    }
  }

  .table__data {
    border-right: 1px solid $border-color;
    min-width: 56px;
    height: 43px;
    padding: 0;
    span {
      padding-right: 10px;
      padding-left: 10px;
    }

    .table__data-value {
      padding: 15px 10px 12px 10px;
      text-align: center;
      width: 100%;
      height: 100%;
    }

    .table__data-wrapper {
      height: 100%;
    }

    .table__row-title {
      .table__data-wrapper {
        max-width: 196px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }
    }
  }

  .header_row {
    height: 30px;
  }

  .year-display {
    position: absolute;
    top: -22px;
    left: 50%;
    width: 35px;
    color: #4a4a4a;
    background-color: #fff;
    transform: translateX(-50%);
  }

  .accent-text {
    color: $color-red;
  }

  .dropdown,
  .dropdown__button {
    width: 100%;
    height: 100%;
  }

  .dropdown__button {
    border-radius: 0;
  }
}

.offices-balance-modal {
  .modal__title {
    margin-bottom: 10px;
  }

  .office-block {
    font-size: 16px;
    font-weight: 500;
    color: #929292;
    margin-bottom: 30px;
  }
  .office-input-block {
    position: relative;
  }
  .reset-button {
    position: absolute;
    z-index: 5;
    right: 0;
    top: 4px;
    border-radius: 0;
    background-color: transparent !important;
  }
}
