.label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $main-font;
  font-weight: bold;
  font-size: $xsmall-font-size;
  line-height: 13px;
  color: $main-bg;
  padding: 3px 8px 2px 8px;
  margin-bottom: 5px;
  border-radius: 12px;
  text-transform: uppercase;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  .icons {
    position: absolute;
    @include icon-center-y;
    left: 6px;
  }

  &--text {
    width: max-content;
    padding: 0;
    color: $color-70;
    text-transform: capitalize;
    line-height: 15px;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 2px;
  }

  &--recognitions {
    width: max-content;
    background-color: $color-recognitions;
    position: relative;
    border-radius: 10px;
    padding-left: 20px;
  }

  &--recognition-icon {
    padding: 16px 20px 16px 0px;
    color: #4a4a4a;
    font-weight: 700;
    font-size: 18px;
  }

  &--vacation {
    background-color: $color-vacation;
  }

  &--sick {
    background-color: $color-sick;
  }

  &--day-off {
    background-color: $color-day-off;
  }

  &--trip {
    background-color: $color-trip;
  }

  &--primary {
    background-color: $color-comp-primary;
    padding-left: 6px;
    padding-right: 6px;
  }

  &--final-decision {
    background-color: $color-final-decision;
  }

  &--not-started {
    background-color: $color-not-started;
  }

  &--answered {
    background-color: $color-answered;
  }

  &--in-progress {
    background-color: $color-status-in-progress;
  }

  &--new {
    background-color: $color-red;
  }

  &--big {
    font-size: $main-font-size;
    line-height: 16px;
    padding: 4px 12px;
  }

  &--bigger {
    height: 24px;
    font-size: $middle-font-size;
    line-height: 19px;
    padding-left: 30px;
    padding-right: 10px;
    border-radius: $border-radius-smaller;

    .icons {
      left: 10px;
    }
  }

  &--inactive-account {
    background-color: $color-dark;
    padding-left: 10px;
  }

  &--planned,
  &--current,
  &--primary,
  &--secondary {
    width: 52px;
    background: $color-final-decision;
    font-size: 9px;
    line-height: 1;
    margin-bottom: 0;
    text-align: center;
    display: inline-block;
    width: auto;
    padding-left: 6px;
    padding-right: 6px;
  }

  &--primary,
  &--current,
  &--base-currency {
    background: $global-color;
  }

  &--approved {
    background-color: $color-status-done;
  }

  &--base-currency {
    display: inline-block;
    width: auto;
    border-radius: 5px;
    padding: 4px 7px;
    font-size: 10px;
  }

  &.capitalazie-label {
    display: block;
    padding-left: 0;
    padding-right: 0;
    text-transform: capitalize;
    font-weight: normal;
  }

  &--color-90 {
    color: $color-90;
  }

  &--default-status {
    width: max-content;
    font-size: 10px;
    border-radius: 4px;
    padding: 2px 4px 1px 4px;
    background-color: $color-dark;
  }
}
