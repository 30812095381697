.modal--new-event-type,
.modal--edit-event-type {
  .modal__body {
    padding-left: 0;
    padding-bottom: 24px;
  }
  .modal__head,
  .modal__event-type-wrapper,
  .modal__event-type-footer {
    padding-left: 30px;
  }
}

.modal__event-type-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 40px;
  padding-right: 30px;
  overflow-y: hidden;
  & ::-webkit-calendar-picker-indicator {
    margin-inline-start: 0;
  }
}

.modal .modal__event-type-column {
  width: 460px;
  margin-bottom: 0;
  max-height: calc(100vh - 251px);

  .modal__preview {
    max-height: unset;

    &-wrapper {
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
      overflow-y: auto;
      @include scroll-styles;
    }

    .textarea-block {
      background-color: $color-20;
    }
  }

  &.form__inputs-wrapper,
  .modal__preview {
    padding-right: 10px;
  }

  .form__group-offices {
    margin-bottom: 0;

    .office-select {
      width: 162px;
    }
    .days-input {
      width: 88px;
    }
    .date-input {
      width: 132px;
    }
    .new-office-btn-wrapper {
      padding-top: 16px;
    }
  }

  .requires-approval {
    margin-bottom: 24px;
  }

  .absence-settings {
    padding-left: 39px;
    margin-bottom: 24px;
  }

  .form__inputs-wrapper {
    overflow-y: hidden;

    .modal__form {
      max-height: unset;
    }
  }

  &:nth-child(1) > .form__input-block:nth-child(1) {
    margin-bottom: 20px;
  }
}

.modal__event-type-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: $table-border;
  padding-top: 24px;
  background: $main-bg;

  &.with-switch {
    flex-wrap: wrap;
    padding-top: 0;
    border-top: 0;
  }
}

.modal__event-type-footer .form__input-block {
  min-width: 214px;
  width: auto;
  margin-bottom: 0;
}

.modal__event-type-footer .form__buttons {
  padding-top: 0;
}

.event-circle {
  position: relative;
}

.event-circle--before {
  padding-left: 28px;
}

.event-circle--before::before {
  left: 0;
}

.event-circle--after {
  padding-right: 28px;
}

.event-circle--after::after {
  right: 0;
}

.event-circle--before::before,
.event-circle--after::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: var(--event-circle-color);
  border-radius: 50%;
}

.form__switches-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.switch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  margin-bottom: 12px;

  &--full {
    width: 100%;
  }
}

.event-circle-preview {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0;
  top: 20%;
  background: #4a4a4a;
  border-radius: 50%;
}

.modal__preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px 20px 30px;
  & .modal__content {
    pointer-events: none;
  }
}

.modal__preview-flag {
  font-family: $main-font;
  font-size: $main-font-size;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba($color-dark, 0.4);
  margin-bottom: 12px;
}

.modal__preview .modal__head {
  margin-bottom: 24px;
}

.modal__preview .modal__title.event-circle {
  padding-right: 27px;

  &::after {
    width: 16px;
    height: 16px;
  }
}

.modal__preview .form__inputs-wrapper .form__inputs-wrapper {
  margin-bottom: 14px;
}

.modal__preview .form__group-wrapper {
  margin-bottom: 10px;
}

.modal__preview .form__input-block {
  &--third {
    width: $width-input-third-preview;
  }

  &--two-third {
    width: $width-input-two-third-preview;
  }
}
.modal__preview .form__inputs-wrapper > .form__input-block,
.modal__preview .form__inputs-subwrapper {
  & + .form__checkbox-block {
    margin-top: 9px;
  }
}

.modal__preview .form__checkbox-block {
  width: auto;
}

.modal__preview .form__textarea {
  height: 92px;
}

.form__history-btn {
  font-family: $main-font;
  font-size: $main-font-size;
  color: $color-red;
  height: 100%;
}

.form__inputs-subwrapper .form__input-block {
  margin-bottom: 0;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.form__add-group-wrapper .form__btn-add-group {
  margin-left: 0;
  margin-bottom: 0;
}

.form__btn-add-group {
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: auto;
  background: $bg-input;
  border-radius: $border-radius-input;
  line-height: 16px;
  font-weight: bold;
  padding: 10px 12px;
  margin-bottom: $fg-wr-margin;
  cursor: pointer;
}

.form__icon-btn-add {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  fill: $color-dark;
}

.form__btn-clean-inputs,
.form__btn-move-inputs,
.form__group__open-btn {
  display: flex;
  cursor: pointer;
  height: $height-input;

  &:hover {
    .form__btn-icon {
      fill: $color-dark;
    }
  }
}

.form__btn-clean-inputs {
  width: calc(#{$width-cross} + 10px);
  padding: 17px 0 17px 10px;
}

.form__group-title {
  font-family: $main-font;
  font-size: $main-font-size;
  line-height: $main-font-size;
  text-transform: uppercase;
  margin-bottom: 9px;
  color: rgba($color-dark, 0.6);
}

.switch-content {
  width: 100%;
  flex-direction: column;
  margin-top: 14px;
}
