.page__candidate-profile {
  .page__profile-alternative-name {
    font-size: 18px;
    color: $color-70;
    font-weight: 700;
    margin-left: 16px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__info-wrapper {
    display: flex;
    flex-grow: 1;
    position: relative;

    .page__profile-block-avatar {
      min-width: 260px;
    }
  }

  .modifyinig-info {
    min-width: unset;
    width: max-content;
    max-width: 290px;

    .data-wrapper {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .data-title {
      margin-bottom: 2px;
    }
  }

  .page__profile-contacts-info {
    width: unset;
    max-width: 735px;
    overflow: hidden;

    .page__profile-name {
      display: block;
    }

    .page__profile-contacts {
      row-gap: 10px;
      column-gap: 30px;
    }

    .page__profile-contact--emails {
      .icons {
        @include size(14px);
      }
    }

    .page__profile-contact {
      display: flex;
      flex-wrap: wrap;
      row-gap: 5px;
      margin-bottom: 0;
      padding-right: 0;
    }

    .page__profile-contact-info,
    .page__profile-contacts {
      margin-bottom: 20px;
    }
  }
}

.tabs__content-item--comments {
  .page__info-block--comments {
    padding: 0;
    border-top: none;
    width: 668px;
  }

  .page__event__info-title {
    font-size: 22px;
    line-height: 22px;
    color: $color-dark;
    text-transform: capitalize;
    margin-bottom: 24px;
  }

  .page__event__count {
    font-weight: 600;
    color: $color-80;
  }
}
