.modal__project-info {
  font-family: $font-stack-helvetica;
  line-height: 20px;
  min-height: 50px;
  padding-right: 27px;

  a {
    color: #06c;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  ul {
    li::before {
      content: '\2022';
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
      margin-right: 0.3em;
      text-align: right;
    }
  }

  ol {
    li {
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-0;

      &::before {
        content: counter(list-0, decimal) '. ';
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
        margin-right: 0.3em;
        text-align: right;
      }
    }
  }
}

.modal__project-no-data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  font-family: $font-stack-helvetica;
}
