.active_employees_table {
  width: 500px;
  min-width: 416px;
  border: 1px solid $border-color;
  border-left: none;

  .switch__block {
    margin: 0 auto;
  }
  .table__head-column:last-child {
    text-align: center;
  }
  .table__row th,
  .table__row td {
    border-right: 1px solid $border-color;
  }

  .table__sub-row:nth-child(2n) .table__data {
    background-color: $bg-table-row;
    background-clip: padding-box;
  }

  .sub_row_table__data {
    position: relative;

    &::before {
      height: 0;
    }
  }
}
