.modal {
  &--new-office {
    .form__group-wrapper .form__inputs-wrapper {
      overflow-y: hidden;
    }

    .form__group .form__input-block--half {
      &:nth-child(1) {
        width: calc(#{$width-input-half} + 11px);
      }

      &:nth-child(2) {
        width: calc(#{$width-input-half} - 11px);
      }
    }

    .form__info-label {
      @include info-name;
      line-height: $main-font-size;
      margin-bottom: 6px;
    }

    .form__btn-add-group {
      display: flex;
      align-items: center;
      align-self: flex-start;
      width: auto;
      background: $bg-input;
      color: inherit;
      border-radius: $border-radius-input;
      line-height: 16px;
      font-weight: bold;
      padding: 10px 12px;
      margin-bottom: $fg-wr-margin;
      cursor: pointer;

      &:hover {
        background: darken($bg-input, 5%);
      }
    }

    .form__btn-clean-inputs {
      width: calc(#{$width-cross} + 10px);
      padding: 17px 0 17px 10px;
      background: none;
      max-height: none;
    }

    .disabled-icon {
      pointer-events: none;
    }

    #hourPerWorkDay {
      min-width: 150px;
    }
  }
}
