.search:not(.icons) {
  display: flex;
  align-items: center;
  position: relative;
  @include size(100%, 36px);
  max-width: 200px;
  &.clearable {
    .search__input {
      padding-right: 32px;
    }

    .search__clear-btn {
      position: absolute;
      right: 14px;
      cursor: pointer;

      .times-circle {
        fill: $color-80;
      }
    }
  }
}

.search__icon {
  position: absolute;
  @include size(16px);

  &--left {
    left: 14px;

    + .search__input {
      padding: 10px 10px 10px 40px;
    }
  }

  &--right {
    right: 14px;

    + .search__input {
      padding: 10px 40px 10px 20px;
    }
  }
}

.search__input {
  width: 100%;
  height: 36px;
  font-family: $main-font;
  font-size: $main-font-size;
  line-height: 16px;
  color: rgba($color-dark, 0.6);
  @include input-styles;
  border-radius: $border-radius-smaller;

  &::placeholder {
    color: rgba($color-dark, 0.6);
  }

  &:hover {
    box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
  }
}
