@keyframes remove {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.portal-notification {
  display: flex;
  position: fixed;
  align-items: center;
  left: 20px;
  bottom: 20px;
  padding: 7px;
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 30px 0px #00000026;
  color: #5aab18;
  font-size: 14px;
  font-weight: 700;
  border-radius: 25px;
  animation: remove 5s;
  animation-fill-mode: forwards;

  span {
    margin-left: 8px;
  }

  & > svg {
    height: 20px;
    width: 20px;
  }

  .close-button {
    margin-left: 14px;
    cursor: pointer;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 22px;
    position: relative;

    svg {
      fill: $color-80;
      cursor: pointer;
      position: absolute;
      height: 13px;
      width: 13px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background-color: $color-dark;
      svg {
        fill: $white;
      }
    }
  }
}
