.hint:not(.table__user-name) {
  padding: 16px !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px !important;
  font-family: $main-font;
  font-size: 14px !important;

  &__transactions {
    max-width: 200px;
    box-shadow: 0px 2px 15px 0px #00000040;
    color: $color-red !important;
    padding: 5px !important;
    font-size: 12px !important;
    line-height: 16px;

    .transactions__hint-content {
      display: flex;
      column-gap: 5px;
    }

    .alert {
      min-width: 12px;
      max-width: 12px;
    }

    &::before {
      content: none;
    }
  }
}

.hint-item {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .hint-item-name {
    font-weight: 700;
    margin-right: 10px;
  }

  .project-alert {
    color: $global-color;
  }
}
