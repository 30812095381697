/*! Main stylesheet */
//------------------------------------------------------------------------------
// Configuration (setup/settings: functions, mixins, variables)
//------------------------------------------------------------------------------

// A Lightweight Sass Tool Set
@import '../../node_modules/bourbon/core/bourbon';

// include-media (vendor functions and mixins, MUST come before variables)
@import '../../node_modules/include-media/dist/include-media';

// Variables (typography, colors, layout)
@import './variables';
@import './mixins';
@import './fonts';

//------------------------------------------------------------------------------
// Global (reset, reboot unclassed selectors)
@import './reset.scss';

//------------------------------------------------------------------------------

// Reset (vendor module)
@import '../../node_modules/normalize.css/normalize';

// Reboot (base, elements, media, tables, Forms)
// @import './core/animations';
// @import './core/typography';
// @import './core/icons';

//------------------------------------------------------------------------------
// Components (designed reusable patterns)
//------------------------------------------------------------------------------

@import './components/icons';
@import './components/button';
@import './components/checkbox';
@import 'components/file-input';
@import 'components/fieldset';
@import './components/color-input';
@import './components/switch';
@import './components/search';
@import './components/branding-radio';
@import './components/dropdown';
@import './components/loader';
@import './components/table';
@import './components/modal';
@import './components/select';
@import './components/label';
@import './components/select';
@import './components/modal-new-office';
@import './components/modal-office';
@import './components/modal-project';
@import './components/textarea';
@import './components/pagination';
@import './components/filter';
@import './components/modal-event-types';
@import './components/modal-project-group';
@import './components/project-hours';
@import './components/user-hours';
@import './components/active-employees';
@import './components/inline-date-picker';
@import './components/poll-templates';
@import './components/polls';
@import './components/resources-page';
@import './components/date-range-picker';
@import './components/resumes';
@import './components/radio-input';
@import './components/infinitie-scroll';
@import './components/modal_user_technical_skills';
@import './components/copy-to-clipboard';
@import './components/grade-block';
@import './components/salaries-page';
@import './components/clients-page';
@import './components/hint';
@import './components/modal-event';
@import './components/modal-absence-block';
@import './components/users-hours-absences-page';
@import './components/employees-timesheet';
@import './components/finance-projects-page';
@import './components/incomes-expenses-report-page';
@import './components/activity-page';
@import './components/days-used-limit';
@import './components/task-tracker-software';
@import './components/event-info';
@import './components/notification-template';
@import './components/transactions';
@import './components/planning-page';
@import './content/candidate/profile';
@import './content/candidate/table';
@import './components/permissions-page';
@import './components/portal-notification';
@import './components/info-block';
@import './components/sort-select';
@import './components/planned-expense';
@import './components/offices-balance';
@import './components/cash-flow-report';
@import './components/dashboard-page';
@import './components/invoices';

//------------------------------------------------------------------------------
// Objects (purely structural reusable objects)
//------------------------------------------------------------------------------

@import './layouts';
@import './content';
@import './header';
@import './menu';

//------------------------------------------------------------------------------
// Utilities (helpers classes, cross-browser bugs, print styles)
//------------------------------------------------------------------------------

// @import './util/helpers';
