.switch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  margin-bottom: 12px;

  &--full {
    width: 100%;
    margin-bottom: 14px;
  }

  &.open {
    margin-bottom: 28px;

    .switch__hidden-content {
      display: flex;
    }
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.switch__block {
  display: block;
  @include size(30px, 18px);
  margin-right: 10px;
}

.switch__input {
  display: none;

  &:checked + .switch__slider {
    background: var(--switch-active-color);

    &::before {
      left: calc(100% - 16px);
      background: $global-text-color;
    }
  }

  &:disabled + .switch__slider {
    opacity: 0.5;
    cursor: unset;
  }
}

.switch__slider {
  display: block;
  width: 100%;
  height: 100%;
  @include size(100%);
  position: relative;
  background: $bg-switch;
  border-radius: $border-radius;
  transition: $main-transition;
  cursor: pointer;

  &::before {
    content: '';
    @include size(14px);
    position: absolute;
    left: 2px;
    @include icon-center-y;
    border-radius: 50%;
    background: $main-bg;
    transition: $main-transition;
  }
}

.switch__title {
  @include text-styles;
}

.switch__hidden-content {
  display: none;
  width: 100%;
  flex-direction: column;
  margin-top: 14px;
}

.switch__hidden-content .form__group-wrapper {
  margin-bottom: 10px;
}

.switch__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--checkboxes {
    padding: 0 14px 14px;
  }
}

.switch__wrapper .form__checkbox-block {
  margin-bottom: 14px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.switch-with-label {
  display: flex;
}