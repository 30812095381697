.permissions-users-table.table.table--hierarchical {
  .action-column {
    width: 4%;
  }

  .pencil {
    color: #4a4a4a;
  }

  .table__dropdown-wrapper button {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;

    &:hover {
      box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .page__profile-block-avatar {
    margin-right: -4px;
  }

  .table__more-users {
    height: 24px;
    width: 24px;
    z-index: 5;
  }

  .table__user-name {
    margin-left: 12px;
    z-index: 10;
  }
}

.edit-users-permissions {
  &.modal .table-wrapper {
    .table {
      tbody .table__row {
        .table__data {
          padding-top: 6px;
          padding-bottom: 6px;
        }

        .table__data-search {
          padding: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  .table__head-column,
  .table__data {
    &:not(:first-child) {
      text-align: center;
    }
  }

  .level-module-name {
    font-size: 14px;
    font-weight: 500;
    color: $color-80;
  }

  .search {
    max-width: unset;

    .search__input {
      background: transparent;
      border-radius: 0;
    }
  }

  .form__checkbox-block,
  .checkbox-no-label {
    min-height: 100%;

    label {
      width: 100%;
      height: 100%;

      &:before {
        transform: translateX(-50%);
        left: 50%;
        top: -8px;
      }

      svg,
      .clear-all-checkbox {
        top: -4px;
        transform: translateX(-50%);
        left: 50%;
      }

      .clear-all-checkbox {
        top: -8px;
      }
    }
  }

  .form__checkbox:disabled + .form__checkbox-label .clear-all-checkbox {
    opacity: 0.2;
  }
}
