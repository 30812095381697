.cash-flow-report {
  margin-bottom: 25px;

  .cash-flow-report-wrapper {
    margin-left: 250px;
    margin-right: 150px;

    .table__head {
      border-top: 0;
    }

    .table__head-column {
      border-right: 1px solid $border-color;
      position: relative;
      border-top: 1px solid $border-color;

      &:not(:first-child) {
        text-align: center;
      }

      &:not(.header_row) {
        &:first-child,
        &:last-child {
          border-top: none;
          height: 66px;
          top: 0;
          padding-top: 22px;
        }

        &:last-child {
          padding-top: 26px;
        }
      }
    }

    .table__row {
      position: static;
    }

    .table__data,
    .table__head-column,
    .header_row {
      &:first-child() {
        position: absolute;
        left: 0;
        width: 250px;
        z-index: 1;

        &:before {
          width: 250px;
        }
      }

      &:last-child {
        position: absolute;
        right: 0;
        width: 150px;
        z-index: 1;
        border-left: 1px solid $border-color;
        border-right: none;
      }

      &:nth-last-child(2) {
        border-right: none;
      }
    }

    .table__data {
      border-right: 1px solid $border-color;
      min-width: 56px;
      height: 43px;
      span {
        padding-right: 10px;
        padding-left: 10px;
      }

      &:not(:last-child) .table__data-wrapper,
      .dropdown__button {
        justify-content: flex-end;
        display: flex;
      }

      .table__data-value {
        padding: 15px 10px 12px 10px;
        text-align: center;
        width: 100%;
        height: 100%;
      }

      &:last-child .table__data-wrapper {
        justify-content: center;
      }

      &:last-child(2) {
        border-left: none;
        background-color: red;
      }

      .table__data-wrapper {
        height: 100%;
      }

      .table__row-title {
        .table__data-wrapper {
          max-width: 196px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
      }
    }

    .total {
      position: absolute;
      right: 0;
      width: 50px;
      min-width: 50px;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
    }

    .header_row {
      height: 30px;
    }

    .table__row:last-child {
      td:first-child {
        border-bottom: 1px solid $border-color;
      }
      &.show-last-devider .first-column {
        border-bottom: 1px solid $table-hierarchical-border-devider;
      }

      font-weight: bold;
    }

    .table__no-data {
      padding: 0;
    }

    .year-display {
      position: absolute;
      top: -22px;
      left: 50%;
      width: 35px;
      color: #4a4a4a;
      background-color: #fff;
      transform: translateX(-50%);
    }

    .dropdown,
    .dropdown__button {
      width: 100%;
      height: 100%;
    }

    .dropdown__button {
      border-radius: 0;
    }
  }
}

.cash-flow-report {
  &-table {
    pointer-events: none;
  }
  &-button {
    pointer-events: initial;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  &-link {
    pointer-events: initial;
    &:hover {
      color: $global-color;
      text-decoration: underline;
    }
  }
}

.cash-flow-report__table-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .cash-flow__block-title {
    font-size: 18px;
    font-weight: 700;
  }

  .cash-flow__report-button {
    padding: 10px;
  }
}

.cash-flow-report-modal {
  .modal__title {
    margin-bottom: 10px;
  }

  .cash-flow-modal-draggable {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .cash-flow-select {
    margin-bottom: 0 !important;
    &__cropped {
      max-width: calc(100% - 46px);
    }
  }

  .cash-flow-placeholder {
    color: $color-80;
  }

  .cash-flow-expenses {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    padding: 12px 8px;
    border-bottom: 1px solid $border-color;
    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      cursor: pointer;
    }
    &-container {
      width: 100%;
    }
    &-buttons {
      padding: 20px 30px 0 0;
    }

    .label {
      font-weight: 400;
      text-transform: none;
    }

    .form__checkbox-block,
    .checkbox-no-label {
      min-height: 100%;

      label {
        width: 100%;
        height: 100%;
        padding-left: 20px;

        &:before {
          left: 0;
          top: 0px;
          transform: none;
        }

        svg,
        .clear-all-checkbox {
          top: 3px;
          left: 3px;
        }

        .clear-all-checkbox {
          top: 0;
          left: 0;
        }
      }
    }
  }
  .cash-flow-expense-block {
    display: flex;
    flex: 1;
  }
  .cash-flow-subexpenses {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 12px 8px 12px 76px;
    border-bottom: 1px solid $border-color;
  }

  input.cash-flow-search {
    border-bottom: 1px solid $border-color;
    background-color: $white;
    border-radius: 0;
  }
  .cash-flow-search-container {
    max-width: 100%;
  }

  .cash-flow-draggable-container {
    position: relative;
  }

  .cash-flow-drag {
    color: #808080;
    cursor: grab;
    &-grabbing {
      cursor: grabbing;
    }
  }
}
