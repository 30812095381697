.planned-expense-wrapper {
  margin-left: 250px;
  margin-right: 150px;

  .table__head {
    border-top: 0;
  }

  .table__head-column {
    border-right: 1px solid $border-color;
    position: relative;
    border-top: 1px solid $border-color;

    &:not(:first-child) {
      text-align: center;
    }

    &:not(.header_row) {
      &:first-child,
      &:last-child {
        border-top: none;
        height: 66px;
        top: 0;
        padding-top: 22px;
      }

      &:last-child {
        padding-top: 26px;
      }
    }
  }

  .table__row {
    position: static;
  }

  .table__data,
  .table__head-column,
  .header_row {
    &:first-child() {
      position: absolute;
      left: 0;
      width: 250px;
      z-index: 1;

      &:before {
        width: 250px;
      }
    }

    &:last-child {
      position: absolute;
      right: 0;
      width: 150px;
      z-index: 1;
      border-left: 1px solid $border-color;
      border-right: none;
    }

    &:nth-last-child(2) {
      border-right: none;
    }
  }

  .table__data {
    border-right: 1px solid $border-color;
    min-width: 56px;
    height: 43px;
    padding: 0;
    span {
      padding-right: 10px;
      padding-left: 10px;
    }

    &:not(:last-child) .table__data-wrapper,
    .dropdown__button {
      justify-content: flex-end;
      display: flex;
    }

    .table__data-value {
      padding: 15px 10px 12px 10px;
      text-align: center;
      width: 100%;
      height: 100%;
    }

    &:last-child .table__data-wrapper {
      justify-content: center;
    }

    &:last-child(2) {
      border-left: none;
      background-color: red;
    }

    .table__data-wrapper {
      height: 100%;
    }

    .table__row-title {
      .table__data-wrapper {
        max-width: 196px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }
    }
  }

  .total {
    position: absolute;
    right: 0;
    width: 50px;
    min-width: 50px;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

  .header_row {
    height: 30px;
  }

  .table__row:last-child {
    td:first-child {
      border-bottom: 1px solid $border-color;
    }
    &.show-last-devider .first-column {
      border-bottom: 1px solid $table-hierarchical-border-devider;
    }

    font-weight: bold;
  }

  .year-display {
    position: absolute;
    top: -22px;
    left: 50%;
    width: 35px;
    color: #4a4a4a;
    background-color: #fff;
    transform: translateX(-50%);
  }

  .dropdown,
  .dropdown__button {
    width: 100%;
    height: 100%;
  }

  .dropdown__button {
    border-radius: 0;
  }
}

.planned-expense-modal {
  .modal__title {
    margin-bottom: 10px;
  }

  .office-block {
    font-size: 16px;
    font-weight: 500;
    color: #929292;
    margin-bottom: 30px;
  }
}
