.user_hours_details_page {
  .page__panel-view {
    margin-right: 20px;
  }

  .check_box_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    height: 36px;
    border-radius: 18px;
    cursor: pointer;
    padding: 9px;

    .checkbox-label {
      text-transform: none;
      line-height: 1px;
      font-weight: normal;
    }
  }
}

.user_hours_table_generalized_view {
  border-left: none;

  .switch__block {
    margin: 0 auto;
  }

  tbody .table__row:not(:last-child) {
    .weight-normal {
      font-weight: normal;
    }
  }

  tbody .table__row:last-child {
    .table__data .table__data-wrapper {
      font-weight: bold;
    }

    .table__data:nth-child(1) {
      padding-left: 22px !important;
    }
  }

  .table__data:not(:first-child) {
    padding-left: 10px;
  }

  .table__row th,
  .table__row td {
    border-right: 1px solid #f0f0f0;
  }

  & > tr.table__row:nth-last-child(2) {
    cursor: auto;
    svg {
      fill: transparent;
      cursor: default;
    }

    .table_button--open-modal {
      cursor: auto;
    }
  }

  .user_hours_header {
    th {
      color: #4a4a4a;
      text-align: center;
    }
    .border-bottom {
      border-bottom: 1px solid #f0f0f0;
    }
  }

  .global {
    color: $global-color;
  }

  .sub_row_table__data {
    position: relative;

    &::before {
      height: 0;
    }
  }
}
