$white: #fff;
$black: #000;
$gray: #333;
$global-text-color: var(--global-text-color);
$global-color: var(--global-color);

$main-bg: $white;
$bg-input: #f5f5f5;
$bg-filter: #e8e8e8;
$bg-input-white: #fdf7f7;
$bg-input-focused: #ededed;
$bg-input-white-focused: #fefbfb;
$bg-tab-active: #f6dede;
$bg-answer: #ffefef;
$bg-switch: #b8b8b8;
$bg-table-row: #f9f9f9;
$table-hover: #f9f9f9;
$table-hierarchical-border-devider: rgba(74, 74, 74, 0.35);
$scroll-color: #e2e2e2;

$border-color: #e6e6e6;
$border-setting-color: #d0d0d0;

$color-dark: #4a4a4a;
$color-dark-transparent: #4a4a4a80;
$color-red: #c1201e;
$color-dark-red: #a31e1c;
$color-green: #119517;
$color-light-red: #ff0000;
$color-orange: #ff8a00;

$color-light-gray: rgba($black, 0.4);
$color-quest-comment: #979797;

$color-recognitions: #f7a738;
$color-vacation: #693f9e;
$color-sick: #309d9b;
$color-day-off: #2b78e4;
$color-trip: #a641b7;
$color-event: #72a34a;
$color-pr: #ff9900;
$color-comp-primary: #c1201e;
$color-final-decision: #b6b6b6;
$color-not-started: #b6b6b6;
$color-adaptation: #1bacc0;
$color-one-to-one: #5d6dbe;
$color-holiday: #c13b2c;
$color-working-off: #e2bb00;
$color-answered: #6a9e3f;

$color-comp-lvl-trainee: #6a9e3f;
$color-comp-lvl-junior: #1bacc0;
$color-comp-lvl-middle: #5d6dbe;
$color-comp-lvl-senior: #b96bc6;

$color-recogn-congratulations: #6a9e3f;
$color-recogn-thank-you: #1bacc0;
$color-recogn-well-done: #5d6dbe;
$color-recogn-great-job: #b96bc6;
$color-recogn-totally-awesome: #f7a738;

$color-current-grade: #f7a738;
$color-grade-experience: #6a9e3f;
$color-prev-experience: #b96bc6;
$color-experience-in-company: #5d6dbe;
$color-full-experience: #1bacc0;

$color-bonuses-th-active: #f7a738;
$color-bonuses-td-active: #6a9e3f;

$color-status-planned: #f7a738;
$color-status-in-progress: #ffac1d;
$color-status-done: #139619;
$color-status-closed: #139619;

$color-clients-light-gray: #c4c4c4;

$bg-hover: #f2f2f2;
$bg-active: #e8e8e8;
$color-poll-question: #f1f1f1;
$color-poll-answer: #fbeeef;
$color-poll-answer-scroll: #f3faf7;

$color-90: #828282;
$color-80: #929292;
$color-70: #adadad;
$color-60: #c9c9c9;
$color-50: #dbdbdb;
$color-40: #e6e6e6;
$color-30: #f0f0f0;
$color-20: #f5f5f5;
$color-10: #f9f9f9;

$calendar-holiday: rgba(74, 74, 74, 0.497077);

$info-block-green: #6a9e3f;
$info-block-red: #da5748;
$info-block-blue: #5d6dbe;
