.modal__transaction,
.original-amount_filter {
  .currency-select {
    min-width: 69px;
    width: 69px;
    .select__value-container {
      padding-right: 20px !important;
    }
  }

  .form__rate-input {
    max-width: 66px;
  }

  .form__currency-operations {
    margin-right: 10px;
    height: 100%;
    height: 46px;
    display: flex;
    align-items: center;
  }
}

.transactions {
  &__page-panel {
    .import-button {
      margin-right: 8px;
    }

    .transactions__total-wrapper {
      display: flex;
    }
  }

  &__content-wrapper {
    margin-bottom: 24px;
    height: calc(100vh - 291px);
    min-height: 412px;

    .select__scroll-manager {
      z-index: -1;
    }
  }

  &__file-preview {
    display: flex;
    column-gap: 16px;
    height: 100%;

    .transactions__preview-table-wrapper {
      width: 80%;
      overflow: auto;
      border: 1px solid $color-60;

      &.empty {
        background-color: $color-20;
        border-color: $color-40;
      }
    }
    .transactions__match-form-wrapper {
      width: 20%;
      border: 1px solid $color-60;
      overflow: auto;

      &.empty {
        background-color: $color-20;
        border-color: $color-40;
      }

      .form {
        &__item {
          padding: 8px;
          line-height: 20px;
          border-bottom: 1px solid $color-40;
          position: relative;

          &.isSelected {
            &::before,
            .select__control::before {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: $global-color;
              opacity: 0.1;
            }

            .select__control::before {
              border-radius: 4px;
            }

            .icons.checkbox {
              fill: $global-color;
              position: absolute;
              right: 8px;
              top: 13.3px;
            }
          }
        }

        &__title {
          font-weight: 700;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &__select-container {
          display: flex;
          column-gap: 7px;

          .select {
            margin-bottom: 0;
            max-width: max-content;
          }

          .form__select {
            display: flex;
            align-items: center;
          }

          .select__control {
            min-height: unset !important;
          }
        }
      }
    }
  }

  &__import-table-wrapper {
    height: 100%;
    overflow: auto;
    border: 1px solid $color-60;
    width: 100%;
  }

  &__table {
    width: calc(100% - 1px);

    &.preview-table {
      td,
      th {
        height: 27px;
        max-width: 350px;
        white-space: nowrap;
      }
      td {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      tr {
        height: 27px;
        max-height: 27px;
        overflow: hidden;

        &:last-child td {
          border-bottom: 1px solid $color-40;
        }
      }
    }

    &.import-table {
      table-layout: fixed;
      height: 1px;

      col:nth-child(1) {
        width: 20px;
      }
      col:nth-child(2),
      col:nth-child(2),
      col:nth-child(3),
      col:nth-child(5),
      col:nth-child(6),
      col:nth-child(7),
      col:nth-child(8),
      col:nth-child(9),
      col:nth-child(14) {
        width: 8%;
        min-width: 150px;
      }

      col:nth-child(4) {
        width: 26%;
        min-width: 150px;
      }

      col:nth-child(10),
      col:nth-child(11),
      col:nth-child(12),
      col:nth-child(13) {
        width: 6%;
        min-width: 150px;
      }

      td.hasError::before,
      td:focus-within:not(.hasError)::before {
        content: '';
        position: absolute;
        width: calc(100% + 3px);
        height: calc(100% + 3px);
        top: -2px;
        left: -2px;
      }

      td.hasError::before {
        border: 2px solid #c1201e;
      }

      td:focus-within:not(.hasError) {
        & > div {
          position: relative;
          z-index: 2;
        }

        &::before {
          border: 2px solid $global-color;
          z-index: 1;
        }
      }

      .form__checkbox-block {
        position: absolute;
        width: 16px;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);

        .icons {
          &.checkbox {
            top: -5px;
            left: -5px;
          }
          &.clear-all-checkbox {
            top: -9px;
            left: -8px;
          }
        }
      }

      .form__checkbox-label {
        padding-left: 0;
      }

      .form__input-block {
        height: 100%;
        margin-bottom: 0;

        &.textarea-wrapper {
          .form__label {
            display: none;
          }

          .form__textarea {
            min-height: 35px;
          }
        }

        &:not(.textarea-wrapper) .form__label {
          top: 15px;
          height: calc(100% - 15px);
        }
      }

      .form__input,
      .form__textarea {
        padding: 0;
        height: unset;
        background-color: $white;
        max-height: unset;
        min-height: unset;

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
      .select {
        .select__control {
          background-color: $white;
        }

        .select__value-container {
          padding: 0 26px 0 0 !important;
        }

        div:not(.select__control) {
          height: 100% !important;
        }
      }
      .transactions__date-input {
        width: 105px;
      }
    }

    .row-header {
      position: sticky;
      top: 0;
      background: $white;
      z-index: 3;

      &-content {
        overflow: hidden;
        text-overflow: ellipsis;
        height: calc(100% + 1px);
        width: calc(100% + 1px);
        padding: 5px;
        border-right: 1px solid $color-40;
        border-bottom: 1px solid $color-40;
      }
    }

    td,
    th {
      position: relative;
      font-weight: 500;
      color: $black;
      height: 100%;
      max-width: 350px;
      vertical-align: top;

      &:last-child {
        border-right: none;
        .row-header-content {
          border-right: none;
        }
      }
    }
    td {
      padding: 5px;
      height: 100%;
      border: 1px solid $color-40;
      vertical-align: top;
      &:last-child {
        border-right: none;
      }
    }
    th {
      border-right: 1px solid white;
      text-align: start;
      min-width: 100px;
      height: 27px;
    }

    tr {
      &.row-header + tr td {
        border-top: none;
        &.hasError::before,
        &:focus-within:not(.hasError)::before {
          top: 1px;
          height: 100%;
        }
      }
      & td:first-child {
        border-left: none;
        width: 20px;
      }

      &:last-child td {
        border-top: none;
      }
    }
  }
}

.transactions__form-select {
  min-width: max-content !important;
  &.rigth {
    right: 0;
  }
}

.page__transactions {
  .button--modal .icons {
    width: 16px;
    height: 16px;
  }

  .button--continue {
    width: unset;
    min-width: 116px;
    padding: 10px 15.5px;
    justify-content: space-between;
  }

  .button--cancel {
    width: 94px;
  }

  .button--back {
    padding: 10px 22px 10px 23px;
    justify-content: space-between;
    width: 100px;
  }
}

.transactions__import-table-wrapper .import-table {
  @supports (-moz-appearance: none) {
    table-layout: auto;
    th .form__checkbox-block {
      left: calc(50% + 1px);
    }

    td.hasError::before {
      left: -2px;
      width: calc(100% + 3px);
    }

    td:focus-within:not(.hasError)::before {
      left: -2px;
      top: -1px;
      width: calc(100% + 3px);
      height: calc(100% + 2px);
    }

    .form__checkbox-block {
      transform: translate(-50%, -50%);
      top: 50%;
    }

    .transactions__date-input {
      width: 118px;
    }
  }
}
