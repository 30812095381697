.poll_template_button_block.page__panel-bottom {
  justify-content: flex-start;
}

.new_template_select {
  max-width: 200px;
  width: 103px;
  margin-bottom: 0;
  vertical-align: middle;
  margin-right: 20px;

  input {
    display: none;
  }

  & > div {
    height: 36px;
    background: none;
    cursor: pointer !important;
  }

  svg.icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: $global-text-color;
  }

  .select__indicators {
    right: 25px;
  }

  .select__single-value {
    color: $global-text-color;
  }

  .select__value-container {
    padding: 10px 10px 10px 35px !important;
    border-radius: 18px;
    height: 36px;
    position: relative;
    background-color: $global-color;

    & > div {
      margin-top: -1px;
      font-weight: bold;
    }
  }
}

.new_template_select_menu.select__menu {
  border-radius: 20px;
  width: 200px;

  .select__menu-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .select__option:last-child {
    position: relative;
    margin-top: 1px;

    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 12px);
      height: 1px;
      top: -1px;
      left: 6px;
      background-color: #f0f0f0;
    }
  }
}

.poll_template_form {
  .question_input,
  .answer_input_wrapper {
    border-radius: 4px;

    .form__textarea {
      min-height: 102px;
      max-height: 102px;
    }
  }

  .question_number,
  .anwser_number {
    padding: 16px 10px 16px 0;
    font-size: 16px;
  }

  .answer_type_select {
    margin-bottom: 10px;
    vertical-align: center;

    & > div {
      height: 46px;
      background: none;
    }

    .select__value-container {
      height: 46px;
    }

    .select__value-container {
      padding: 10px !important;
      font-size: 14px;
      position: relative;

      & > div {
        margin-top: -1px;
      }
    }
  }

  .answer_block {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #f0f0f0;
      position: absolute;
      left: 0;
      top: 23px;
    }
  }

  .checkbox_template_form {
    width: auto;
    margin-right: 10px;
    font-weight: initial;
    text-transform: none;
    padding-left: 0;
  }

  .answer_input_wrapper {
    &:after {
      content: 'Separate choices by a semicolon (;)';
      width: 100%;
      display: block;
      color: rgba(74, 74, 74, 0.5);
      padding-top: 10px;
    }
  }

  .form__inputs-subwrapper {
    align-items: flex-start;
  }

  .custom_answer_button {
    width: 100%;
    border-radius: 4px;
    padding: 15px;
    min-height: 46px;
    font-weight: normal;
    justify-content: flex-start;

    &.error {
      color: #c1201e;
    }
  }

  .custom_answers_error {
    color: #c1201e;
    padding: 0 12px;
    font-size: 12px;
    line-height: 12px;
  }
}

.preview_template_category_title {
  margin-bottom: 0;

  .modal__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: $global-color;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}

.preview_template_modal {
  top: 70px;

  .modal__wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 60vh;
    @include scroll-styles;
  }

  .modal__body {
    max-height: 80vh;
  }

  .modal__content {
    display: block;
  }

  .preview_template_modal__content {
    padding-right: 27px;
  }

  .answer_wrapper {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
    align-items: center;

    &:first-child {
      padding-top: 0;
    }
  }

  .answer_block {
    width: calc(100% - 70px);
  }

  .right_answer_marker {
    color: $color-green;
    margin-left: 10px;
    font-weight: bold;
    width: 70px;
    text-align: end;
    text-transform: uppercase;
  }

  .page__profile-block-avatar {
    margin-right: 5px;
  }
}

.custom_anwser_form {
  .form__inputs-subwrapper .form__checkbox-block {
    margin-top: 14px;
  }
}

.template_table {
  .poll-templates__show-modal {
    text-align: start;
    span {
      font-weight: bold;
      color: #4a4a4a;
    }
  }

  .table__head-column {
    &:nth-child(7) {
      width: 4%;
    }

    &:nth-child(6):not(:last-child) {
      width: 6%;
    }

    &:nth-child(5),
    &:nth-child(3) {
      width: 10%;
    }
    &:nth-child(4),
    &:nth-child(2) {
      width: 15%;
    }
  }
}

.template_select {
  .dropdown__list-item:first-child() {
    .button__icon {
      height: 12px;
      width: 12px;
      margin-right: 10px;
    }
  }
  .dropdown__list-item__wrapper {
    padding: 0 12px;
  }
}
