.modal {
  display: none;
  justify-content: center;
  @include size(100%);
  @include position(fixed, 0 null null 0);
  background-color: $color-light-gray;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 15;

  &.open {
    display: block;

    @at-root body.modal-open {
      overflow: hidden;
    }
  }

  &--popover {
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    padding: 0;
    pointer-events: none;
    @include icon-center-x;

    .modal__title {
      font-size: 20px;
      line-height: 30px;
    }

    .modal__options-btn {
      margin-right: 24px;
    }
  }

  &--small {
    & > .modal__body {
      width: 500px;
    }
  }

  &--big {
    & > .modal__body {
      width: 750px;
    }
  }

  &--large {
    & > .modal__body {
      width: 1000px;
    }
  }

  &--edit-profile {
    .modal__body {
      width: 532px;
    }

    .form__dropdown-wrapper {
      align-items: flex-start;
    }

    .form__group {
      margin-bottom: 14px;
    }

    .form__btn-add-group {
      height: 46px;
    }
  }

  &--edit-proffesional-info {
    .form__group-wrapper .form__inputs-wrapper {
      overflow: unset;
      padding-right: 0;
    }
  }

  &--new-office,
  &--event {
    .form__week-checkboxes-wrapper {
      display: flex;
      margin-right: 10px;
    }

    .form__week-checkbox-block {
      width: 46px;
      height: $height-input;
      position: relative;
      margin-right: 3px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    .form__week-checkbox {
      display: none;

      &:checked + .form__week-label {
        background: var(--global-color-oppacity);
        color: $global-color;

        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: $global-color;
          border-radius: 4px;
          opacity: 0.1;
        }
      }
    }

    .form__week-label {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      @include size(100%);
      top: 0;
      left: 0;
      @include input-styles;
      cursor: pointer;
    }
  }

  &--event {
    ::-webkit-calendar-picker-indicator {
      margin-inline-start: 0;
    }

    .form__event-time-wrapper {
      input[type='date']::-webkit-input-placeholder {
        visibility: hidden !important;
      }
    }

    .form__week-checkboxes-wrapper {
      margin: 0;
    }

    .form__week-checkbox-block {
      width: 100%;
    }
  }

  &.change_password_modal {
    .send_details_checkbox {
      display: block;
      text-transform: none;
      margin-top: 14px;
    }

    .random_password_input {
      opacity: 0;
    }
  }

  &.edit_positions_history {
    .position_block-wrapper {
      align-items: flex-start;
    }

    .position_block {
      padding-right: 0;
      overflow-y: hidden;

      .grade_input {
        width: calc(calc((100% - 20px) / 3) * 2);
      }
    }

    .form__inputs-subwrapper {
      align-items: flex-start;
    }
  }

  &--edit-permissions.modal {
    .form__checkbox-label {
      &::before {
        width: 14px;
        height: 14px;
      }
    }

    .permissions-table {
      thead {
        background-color: #f5f5f5;
      }
    }

    .form__inputs-wrapper {
      max-height: calc(100vh - 268px);
      margin-top: 20px;
    }

    .search {
      max-width: calc(100% - 27px);
    }

    .clear-all-checkbox {
      .icons.clear-all-checkbox {
        width: 1em;
        height: 1em;
        left: -7px;
        top: -7px;
      }
    }

    .icons.checkbox {
      top: -5px;
    }

    .button--modal:last-child {
      margin-right: 0;
    }

    .form__checkbox-label:before {
      top: 0;
    }
  }

  &.skill-level-modal {
    .form__inputs-wrapper {
      margin-bottom: 40px;
    }
  }

  &.center .modal__body {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.center.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    .modal__body {
      top: unset;
      left: unset;
      transform: unset;
    }
  }
}

.modal__body {
  width: 500px;
  height: max-content;
  max-height: calc(100vh - 40px);
  background: $main-bg;
  pointer-events: auto;
  padding: 26px 3px 30px 30px;
  border-radius: $border-radius;
  box-shadow: $modal-box-shadow;
  position: relative;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 10px);
  @include scroll-styles;
}

.modal__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-right: 27px;

  &--smaller-margin {
    margin-bottom: 19px;
  }
}

.modal__title {
  font-family: $main-font;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 30px;
  color: $color-dark;
  max-width: calc(100% - 40px);
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.modal__subtitle {
  font-family: $main-font;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 16px;
  color: $color-dark;
}

.modal__options {
  display: flex;
  align-items: center;
}

.modal__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal__options-btn {
  @include size(20px);
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    .modal__options-icon {
      opacity: 1;
    }
  }

  &:nth-last-child(1) {
    margin-right: 0;
  }

  &.dropdown__button {
    @include size(20px);
  }
}

.modal__options-icon {
  @include size(13.5px);
  fill: $color-dark;
  transition: $main-transition;
}

.modal__options-btn {
  @include size(20px);
  margin-right: 20px;
  background: none;
  padding: 0;
  cursor: pointer;

  &.modal__close:hover {
    box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
  }

  &:nth-last-child(1) {
    margin-right: 0;
  }

  &.dropdown__button {
    @include size(20px);
  }
}

.modal .form__buttons {
  background: $main-bg;
  padding-right: 27px;

  &-wrapper {
    width: 100%;
    padding-right: 27px;
    margin-bottom: 12px;
  }
}

.modal .form__inputs-wrapper {
  max-height: calc(100vh - 212px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 27px;
  line-height: normal;
  @include scroll-styles;
}

.modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  @include size(36px);
  padding: 0;
  border-radius: 50%;
}

.modal__grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, calc((100% - 20px) / 2));
  grid-gap: 20px;
  margin-bottom: 30px;
}

.modal__grid--three-col {
  grid-template-columns: repeat(3, calc((100% - 40px) / 3));
}

.modal__grid__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-bottom: 34px;
  margin-bottom: 0;
}

.modal__grid__item:nth-last-child(1) {
  margin-bottom: 0;
}

.modal__grid__item-name {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  line-height: 12px;
  opacity: 0.6;
  margin-bottom: 2px;
  margin-bottom: 4px;
}

.modal__grid__item-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
}

.template_name {
  margin-bottom: 30px;
}

.modal__questions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.modal__questions-title {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 12px;
  color: rgba(74, 74, 74, 0.6);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  text-align: left;
}

.modal__questions-head {
  margin-bottom: 20px;
}

.modal__questions-item {
  margin-bottom: 30px;

  &-number {
    line-height: 18px;
    margin-right: 3px;
  }
}

.modal__questions-head:nth-last-child(1),
.modal__questions-item:nth-last-child(1) {
  margin-bottom: 0;
}

.modal__questions-head,
.modal__questions-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal__questions-body {
  margin-bottom: 20px;
}

.modal__questions-title:nth-child(1),
.modal__questions-item-name:nth-child(1),
.modal__questions-item-value:nth-child(1) {
  margin-right: 20px;
}

.modal__questions-title {
  width: calc((100% - 20px) / 2);
}

.modal__questions-item-name {
  line-height: 18px;
  width: calc(((100% - 20px) / 2) - 8px);
}

.modal__questions-item-value {
  width: calc(((100% - 20px) / 2) + 8px);
  line-height: 18px;
}

.modal__post-history-list {
  display: flex;
  flex-direction: column;
  max-height: 418px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 24px;
  @include scroll-styles;
  width: 100%;
  min-height: 40px;
}

.modal__post-history-item {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 12px;
  line-height: 1.14;
  border-radius: 4px;
  margin-bottom: 2px;

  &:first-child {
    border-top: 1px solid #f0f0f0;
  }
}

.modal__post-history-item-title {
  font-weight: 500;
  padding: 10px;

  @at-root .modal__post-history-item--passed {
    color: rgba($color-dark, 0.5);
  }

  @at-root .modal__post-history-item--current {
    color: $global-color;
  }
}

.modal__post-history-item-date {
  padding: 10px;
  text-align: end;

  @at-root .modal__post-history-item--passed {
    color: rgba($color-dark, 0.5);
  }

  @at-root .modal__post-history-item--current {
    color: $global-color;
  }
}

.modal__post-history-item-label {
  text-align: center;
}

.modal__post-history-item-done-icon {
  width: 14px;
  height: 14px;
}

.modal__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  .modal__info-row {
    margin-bottom: 0;
  }
}

.modal__info-row {
  @include info;
  margin-bottom: 20px;

  &--participants .modal__info-row__avatar {
    margin-right: 10px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  &--questionnaires .modal__info-row__value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &--half {
    width: calc((100% - 20px) / 2);
  }
}

.modal__info-row__name,
.modal__grid__item-name {
  @include info-name;
  margin-bottom: 4px;
}

.modal__info-row__value,
.modal__grid__item-value {
  @include info-value;

  &--red {
    color: $color-red;
  }

  &--global {
    color: $global-color;
  }
}

.modal__event__organization-info {
  margin-bottom: 20px;
}

.modal__title .bonus-exp-title {
  font-size: 14px;
}

.modal--reorder-links .table__data .custom-link {
  color: #000;
}

.recognition-type.form__inputs-wrapper {
  margin-bottom: 40px;

  .form__input-block--recognition-type-name {
    margin-bottom: 24px;
  }
}

.form__note {
  margin-bottom: 16px;
  color: $color-90;
  overflow: hidden;
  line-height: 16px;

  &.accent {
    color: $global-color;
  }
}

.modal__info-row {
  .page__profile-block-avatar a:hover {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
