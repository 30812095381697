.sort-select {
  .direction-button {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 0;

    &:hover {
      background: $bg-input;
    }
  }

  .filter__value-container {
    padding: 10px !important;
  }

  .filter__option {
    padding: 0 36px !important;
    position: relative;
  }

  .check {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }
}
