.grade-block {
  width: calc((100% - 40px) / 5);
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba($color-dark, 0.08);
  color: $color-dark;
  border-radius: $border-radius-smaller;
  padding: 12px 20px 8px;
  margin-right: 10px;

  &:nth-last-child(1) {
    margin-right: 0;
  }

  &--current-grade {
    background-color: rgba($color-current-grade, 0.08);
    color: $color-current-grade;

    .grade-block__name-info .button-info__icon {
      fill: $color-current-grade;
    }
  }

  &--grade-experience {
    background-color: rgba($color-grade-experience, 0.08);
    color: $color-grade-experience;

    .grade-block__name-info .button-info__icon {
      fill: $color-grade-experience;
    }
  }

  &--prev-experience {
    background-color: rgba($color-prev-experience, 0.08);
    color: $color-prev-experience;

    .grade-block__name-info .button-info__icon {
      fill: $color-prev-experience;
    }
  }

  &--experience-in-company {
    background-color: rgba($color-experience-in-company, 0.08);
    color: $color-experience-in-company;

    .grade-block__name-info .button-info__icon {
      fill: $color-experience-in-company;
    }
  }

  &--full-experience {
    background-color: rgba($color-full-experience, 0.08);
    color: $color-full-experience;

    .grade-block__name-info .button-info__icon {
      fill: $color-full-experience;
    }
  }

  &--level {
    width: calc((100% - 30px) / 4);
    padding-top: 7px;

    .grade-block__name {
      line-height: 14px;
      margin-bottom: 6px;
    }
  }

  &--trainee {
    background-color: rgba($color-comp-lvl-trainee, 0.08);
    color: $color-comp-lvl-trainee;
  }

  &--junior {
    background-color: rgba($color-comp-lvl-junior, 0.08);
    color: $color-comp-lvl-junior;
  }

  &--middle {
    background-color: rgba($color-comp-lvl-middle, 0.08);
    color: $color-comp-lvl-middle;
  }

  &--senior {
    background-color: rgba($color-comp-lvl-senior, 0.08);
    color: $color-comp-lvl-senior;
  }
}

.grade-block__total {
  font-size: $large-font-size;
  line-height: 22px;
  font-weight: bold;
}

.grade-block__name {
  display: flex;
  align-items: center;
  font-size: $small-font-size;
  line-height: 22px;
}
