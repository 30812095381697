@font-face {
  font-family: 'Roboto';
  src: url($path-fonts + 'Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url($path-fonts + 'Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url($path-fonts + 'Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url($path-fonts + 'Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
