.inline_date_picker {
  display: flex;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  .switch_forward_arrow_button.button.button--circle {
    margin-right: 10px;
  }
  .switch_back_arrow_button {
    margin-left: 10px;
  }

  & .datepicker-container {
    position: relative;

    &:hover .daterangepicker-input {
      box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
    }
  }

  button {
    background: $bg-input;
    color: $gray;

    &:hover:not(.apply--button) {
      background: rgba(0, 0, 0, 0.08);
    }
  }
  & .dropdown {
    margin-right: 30px;

    & .dropdown__list-item:hover {
      opacity: 0.6;
    }
    & .dropdown__list-item.active {
      background: var(--dropdown-active-color);
      opacity: 1;
      span {
        color: #fff;
      }
    }
    & .dropdown__list-item button {
      background: none;
      opacity: 1;
    }

    & .dropdown__button {
      padding: 10px 10px 10px 10px;
      width: 100px;
      box-sizing: border-box;
      font-weight: bold;
      height: 36px;
      border-radius: 18px;
      & .button__text {
        margin-right: 10px;
      }
      & .icons {
        height: 16px;
        width: 16px;
      }
    }
  }

  .current_period {
    width: auto;
    padding: 10px 16px;
    border-radius: 18px;
    background: $bg-input;
    font-weight: bold;
    line-height: 16px;
    margin: 0;

    &.daterangepicker-input {
      cursor: pointer;
    }
  }

  .today_button,
  .period_selector.filter {
    margin-right: 20px;
  }
}

.select.period_selector {
  width: auto;
  margin-bottom: 0;
  margin-right: 30px;

  & > div {
    height: 36px;
    background: none;
  }
  .select__value-container {
    border-radius: 18px;
    height: 36px;
  }

  .select__control:hover {
    & .select__value-container {
      box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
    }

    & .icons.select-arrow {
      cursor: pointer;
    }
  }

  .select__value-container {
    padding: 10px 20px 10px 10px !important;
    position: relative;
    cursor: pointer;
    font-weight: bold;

    & > div {
      margin-top: -1px;
    }
  }

  .select__single-value {
    max-width: none;
    margin-right: 10px;
  }

  .select__indicators {
    right: 10px;
  }
}
