.event-poll {
  border: $table-border;
  padding: 0 0 10px 10px;
  border-radius: 4px;
  margin-top: 15px;

  &__dropdown {
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-top: 10px;
    cursor: pointer;

    &.disable {
      cursor: unset;

      .event-poll__dropdown-title {
        margin-left: 24px;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 18.75px;
      color: $color-dark;
      margin-left: 10px;
    }

    &--left {
      display: flex;
      align-items: center;
    }

    &--right {
      margin-left: auto;
    }
  }

  &__content {
    display: none;
    @include scroll-styles;
    &--open {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      // overflow-x: auto;
    }

    &-header {
      display: flex;
      align-items: center;
      box-sizing: content-box;
      height: 45px;
      width: max-content;
      background-color: $white;
      z-index: 10;

      .polls__section--question-wrapper {
        height: 85px;
        padding-top: 45px;
      }

      & > * {
        text-align: left;
      }

      &--title {
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: $color-dark;
      }

      &--responder {
        display: flex;
        align-items: center;
        margin-right: 20px;

        &:last-child {
          margin-right: 30px;
        }

        &-data {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        &-avatar {
          margin-right: 8px;
        }

        &-name {
          height: 16px;
          width: 206px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: $color-dark;
          margin-bottom: 4px;
        }

        &-position {
          height: 12px;
          width: 206px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          line-height: 10px;
          color: $color-dark;
          opacity: 0.6;
        }
      }
    }

    &-questions {
      display: flex;
      position: relative;

      .react-horizontal-scrolling-menu {
        &--scroll-container {
          @include scroll-styles;
          display: block;
          cursor: move; /* fallback if grab cursor is unsupported */
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;

          &.grabbing {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
          }
        }
      }
    }

    &__group {
      position: relative;
      margin-bottom: 25px;

      &-name {
        padding: 0 280px;
        display: flex;
        justify-content: center;

        &.my-polls-title {
          padding: 0;
        }
      }
    }

    &-question {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__group {
        display: flex;
        flex-direction: row;
        width: 100%;

        .event-poll--element:nth-child(2) {
          margin-left: 13px;
        }
      }
    }
  }

  &--element {
    min-width: 250px;
    max-width: 250px;
    overflow-wrap: break-word;
    height: max-content;
    position: relative;

    &.no-background::before {
      background-color: unset;
    }
  }

  &--last-element {
    min-width: 10px;
    max-width: 10px;
    height: 100%;
    min-height: 1px;
  }

  .event-poll__dropdown--right {
    display: flex;
    align-items: flex-end;

    & > .label {
      width: unset;
      padding-left: 10px;
      margin-right: 20px;
    }

    .dropdown__button {
      background-color: transparent;

      .dropdown__list {
        z-index: 10;
      }
    }
  }
}
.react-horizontal-scrolling-menu--item:first-child {
  .event-poll--element {
    margin-top: 12px;
  }
}
@media (min-width: 1400px) {
  .main.main--open-menu {
    .poll-header:not(.poll-info) {
      width: calc(100vw - 500px);
    }
  }
}

.poll-header {
  width: calc(100vw - 221px);
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &.poll-info {
    width: 100%;
  }

  .event-poll--element:nth-child(2) {
    margin-left: 3px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &-wrapper {
    width: 100%;
    height: 45px;
    margin-bottom: 38px;
  }
}

.event-poll__first-column-element {
  width: 280px;
  height: calc(100% - 13px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: white;
}
