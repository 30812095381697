.modal.absence-user-block {
  position: absolute;
  background-color: unset;
  width: 375px;
  height: max-content;
  transition: all 0.3s ease-in-out;

  .modal__content {
    padding-right: 27px;
  }

  .modal__body {
    width: 100%;
    overflow: unset;
  }

  .dropdown__button {
    width: 63px;

    .current-year {
      font-size: 20px;
      font-weight: 700;
      margin-right: 3px;
    }
  }
}

.absence-block {
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px dashed $color-60;
    padding: 8px 0;
    font-size: 12px;

    &:last-child {
      border-bottom: 1px dashed $color-60;
    }
  }

  &__name {
    opacity: 0.6;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.more {
      font-weight: 700;
      cursor: pointer;
    }
  }

  &__number {
    padding-left: 16px;
    padding-bottom: 2px;
    white-space: nowrap;
    line-height: 14px;

    &.scheduled {
      color: $color-80;
      margin-top: 4px;
    }
  }

  &__number-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
