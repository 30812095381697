.copy-to-clickboard-wrapper {
  position: relative;

  &:hover {
    & .text-to-copy {
      text-decoration: underline;
    }

    & .copy-icon {
      opacity: 0.6;
    }
  }

  & .copy-icon {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: -19px;
    height: 12px;
    width: 12px;
    box-sizing: content-box;
    padding-left: 8px;
    transition: opacity 0.3s ease-in-out;

    & svg {
      margin-right: 0;
    }
    &:hover {
      svg {
        fill: $color-red;
      }
    }
  }
}
