.table__pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0;

  .table__pagination-pages {
    min-width: 65px;
    height: 100%;

    .loader {
      width: 25px;
      height: 25px;
    }
  }

  .table__pagination-items {
    display: flex;

    .table__pagination-item {
      background-color: $bg-input;
      @include size(36px);

      &-link {
        @include size(36px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-smaller;
        font-weight: bold;
        transition: 0.15s;

        &--disabled {
          color: rgba(74, 74, 74, 0.5);
        }
      }

      &-prev {
        margin-right: 10px;
        border-radius: $border-radius-smaller;
      }

      &-next {
        margin-left: 10px;
        border-radius: $border-radius-smaller;
      }

      &:nth-child(2) {
        border-radius: $border-radius-smaller 0 0 $border-radius-smaller;
      }

      &:nth-last-child(2) {
        border-radius: 0 $border-radius-smaller $border-radius-smaller 0;
      }

      &:not(.table__pagination-item-link--disabled) {
        cursor: pointer;

        &:not(.active):hover {
          .table__pagination-item-link {
            background-color: rgba(74, 74, 74, 0.2);
            .icons {
              color: $color-dark;
            }
          }
        }
      }

      &.active {
        a {
          border-radius: $border-radius-smaller;
          background-color: var(--global-color);
          color: $white;
        }
      }
    }
  }

  .select {
    max-width: 64px;

    .select__value-container {
      cursor: pointer;
      border-radius: $border-radius-smaller;
      max-height: 36px;
      padding: 10px 10px 10px 15px !important;

      &:hover {
        background-color: rgba(74, 74, 74, 0.2);
      }
    }
  }
}
