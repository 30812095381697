.loader {
  @include size(1em);

  &.event-departments-loader {
    width: 100%;
    height: 100%;
  }

  svg,
  circle {
    width: inherit;
    height: inherit;
    fill: currentColor;
  }
  path {
    stroke: currentColor;
  }
}
