@at-root .form--cv .form__group #{&} {
  margin-bottom: 10px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

@at-root .form__tabs-settings-item-content #{&} {
  margin-bottom: 14px;
}

@at-root .modal__footer #{&} {
  margin-bottom: 0;
}

.form__tabs {
  display: flex;
  max-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}

.form__tabs-head {
  width: 230px;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  @include scroll-styles;
}

.form__tabs-content {
  width: calc(100% - 230px);
  max-height: calc(100vh - 212px);
  padding-left: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 27px;
  padding-bottom: 40px;

  @include scroll-styles;

  .form__group-wrapper > div {
    margin-bottom: 0;
  }

  .form__input-block > .project_select {
    margin-bottom: 10px;
  }

  .add_language_btn {
    margin: 14px 0 20px;
  }

  .form__inputs-subwrapper > .form__inputs-subwrapper {
    margin-bottom: 10px;
  }
}

.form__tabs-head-item {
  display: flex;
  align-items: center;
  height: 48px;
  background: $bg-input;
  padding: 12px 10px;
  margin-bottom: 1px;
  cursor: pointer;

  &.active {
    color: $global-color;
    font-weight: 500;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $global-color;
      opacity: 0.1;
    }
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  .form__user {
    display: flex;
    align-items: center;
  }

  .form__user-name {
    margin-left: 8px;
  }
}

.form__tabs-content-cvs {
  display: none;

  &.active {
    display: block;
    animation: $animation;
  }
}

.form__tabs-settings {
  display: none;
  flex-direction: column;

  &.active {
    display: flex;
    animation: $animation;
  }
}

.form__tabs-settings-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 31px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.form__tabs-settings-item-title {
  font-size: $small-font-size;
  line-height: 1.16;
  color: rgba($color-dark, 0.6);
  margin-bottom: 11px;
}

.form__tabs-settings-item-content {
  column-count: 2;
  column-gap: 30px;

  &--row {
    display: flex;
    column-count: unset;
    column-gap: unset;
  }
}

.form__tabs-final-cvs {
  display: none;

  &.active {
    display: flex;
    animation: $animation;
  }
}

.form__tabs-final-cvs-item {
  display: none;

  &.active {
    display: flex;
    animation: $animation;
  }
}

.form__edit-cv-top {
  display: flex;
  margin-bottom: 30px;
}

.form__edit-cv-top-inputs {
  width: calc(100% - 158px);
  padding-left: 20px;
}

.form__edit-cv-name {
  font-size: $large-font-size;
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 14px;
}

.form__edit-cv-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form__edit-cv-tabs-headers {
  width: 100%;
  display: flex;
  background: $bg-input;
  border-radius: $border-radius-smaller;
  padding: 3px 2px;
  margin-bottom: 30px;
}

.form__edit-cv-tab-header {
  width: calc(100% / 3);
  border-radius: $border-radius-smaller;
  display: flex;
  justify-content: center;
  font-weight: 700;
  padding: 8px;
  transition: 0.3s;
  color: $color-dark;
  cursor: pointer;

  &.active {
    background: #fff;
    color: $color-dark;
  }
}

.form__edit-cv-tab-content {
  display: none;
  flex-direction: column;

  &--about,
  &--skills {
    .form__group-wrapper {
      margin-bottom: 10px;

      &:nth-last-child(2) {
        margin-bottom: 24px;
      }
    }
  }

  &.active {
    display: flex;
    animation: $animation;
  }
}

.language_block {
  align-items: flex-start;
  margin-bottom: 0;
}

.cv_form_footer {
  border-top: 1px solid #f0f0f0;
  display: flex;
  align-items: flex-end;
  padding-top: 30px;
  position: relative;
  margin-right: 32px;

  .form__error {
    position: absolute;
    top: 5px;
    left: 10px;
  }

  .label {
    justify-content: flex-start;
  }

  .form__buttons {
    padding-right: 0;
  }
}

.cv_settings_checkbox_block {
  display: flex;
  .label {
    text-transform: none;
    justify-content: flex-start;
    font-weight: normal;
    padding-left: 0;
  }

  .cv_settings_checkbox_column {
    width: 50%;
  }
}

.skill_group_select_menu.select__menu {
  border-radius: 5px;
  width: 200px;

  .select__menu-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.resumes_filter_block {
  flex-wrap: wrap;

  .page__panel-bottom__wrapper--people.margin_bottom {
    margin-bottom: 10px;
  }
}

.technical_skills_filter,
.experience_filter {
  &.dropdown__list {
    padding: 20px;
    width: 256px;
  }

  .select__scroll-manager {
    display: none;
  }
}

.experience_filter {
  .form__inputs-subwrapper {
    margin-bottom: 20px;
    align-items: flex-start;

    input {
      height: 40px;
    }
  }

  .form__buttons button {
    width: 100px;
    margin: 0;
  }
}

.technical_skills_filter {
  &.dropdown__list {
    width: 400px;
    overflow: auto;
  }

  .select__scroll-manager {
    display: none;
  }

  .form__btn-clean-inputs {
    margin-right: 0;
  }
}

.edit_cv_modal {
  .form__tabs-content {
    width: 100%;
  }
}

.page__cv-document {
  max-width: 958px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 21px 30px 15px;
  align-self: center;
  width: 100%;

  p,
  ul {
    margin-bottom: 6px;
  }

  li {
    padding-left: 8px;
    margin-bottom: 6px;
    position: relative;

    &::before {
      content: '•';
      position: absolute;
      top: 0;
      left: 0;
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  *:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.page__cv-document-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding-bottom: 16px;
  margin-bottom: 30px;
}

.page__cv-document-head-info {
  display: flex;
  flex-direction: column;
  color: rgba($color-dark, 0.5);
  line-height: 1.14;
}

.page__cv-document-company {
  font-weight: 700;
  margin-bottom: 4px;
}

.page__cv-document-logo {
  max-width: 87px;
  height: 38px;
}

.page__cv-document-logo-svg {
  width: 100%;
  height: 100%;
}

.page__cv-document-body {
  display: flex;
  flex-wrap: wrap;
}

.page__cv-document-avatar-wrap {
  width: 260px;
  height: 260px;
  margin-bottom: 30px;
}

.page__cv-document-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 30px;
}

.page__cv-document-info {
  width: calc(100% - 260px);
  display: flex;
  flex-direction: column;
  font-size: $middle-font-size;
  line-height: 1.12;
  padding-left: 30px;
  margin-bottom: 30px;

  b {
    font-weight: 700;
  }
}

.page__cv-document-name {
  font-size: 30px;
  line-height: 1.16;
  font-weight: 700;
  margin-bottom: 21px;
}

.page__cv-document-post {
  font-size: $large-font-size;
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 17px;
}

.page__cv-document-experience {
  line-height: 1.15;
  margin-bottom: 17px;
}

.page__cv-document-education {
  display: flex;
  flex-direction: column;
  line-height: 1.15;
  margin-bottom: 17px;
}

.export-modal {
  .page__cv-document-head-info {
    font-size: 10px;
  }

  .page__cv-document-logo {
    max-width: 67px;
    height: 28px;
  }

  .page__cv-document-avatar-wrap {
    width: 160px;
    height: 160px;
  }

  .page__cv-document-name {
    font-size: 25px;
    line-height: 1;
    margin-bottom: 10px;
  }

  .page__cv-document-post {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 10px;
  }

  .page__cv-document-experience,
  .page__cv-document-education,
  .page__cv-document-langs {
    line-height: 1;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .page__cv-document-stack-th {
    font-size: 10px;
  }

  .page__cv-document-additional-item-title,
  .page__cv-document-additional-item-content,
  .page__cv-document-stack {
    font-size: 12px;
  }
}

.page__cv-document-education-degree {
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 5px;
}

.page__cv-document-langs {
  display: flex;
  flex-direction: column;
}

.page__cv-document-lang {
  margin-bottom: 5px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.page__cv-document-stack-wrapper {
  max-width: 100%;
  overflow: scroll;
  @include scroll-styles;
  margin-bottom: 30px;
}

.page__cv-document-stack {
  width: 100%;
  border: 1px solid $border-color;
}

.page__cv-document-stack-thead {
  border-bottom: 1px solid $border-color;
}

.page__cv-document-stack-th {
  width: 129px;
  font-size: $small-font-size;
  font-weight: 700;
  color: rgba($color-dark, 0.6);
  padding: 4px 10px 2px;
  vertical-align: middle;
  text-align: left;
  border-right: 1px solid $border-color;

  &:nth-last-child(1) {
    border-right: unset;
  }
}

.page__cv-document-stack-td {
  padding: 10px 10px 12px;
  text-align: left;
  border-right: 1px solid $border-color;

  &:nth-last-child(1) {
    border-right: unset;
  }
}

.page__cv-document-stack-td,
.page__cv-document-stack-th {
  width: 12.5%;
}

.page__cv-document-additional {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.page__cv-document-additional-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  &:nth-child(2) {
    padding-right: 0;
    padding-left: 20px;
  }
}

.page__cv-document-additional-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  li {
    line-height: 1.15;
  }
}

.page__cv-document-additional-item-title {
  font-size: 17px;
  line-height: 1.15;
  color: $global-color;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.page__cv-document-additional-item-content {
  line-height: 1.45;

  a {
    color: #06c;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  ul {
    li::before {
      content: '\2022';
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
      margin-right: 0.3em;
      text-align: right;
      position: static;
    }
  }

  ol {
    li {
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-0;

      &::before {
        content: counter(list-0, decimal) '. ';
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
        margin-right: 0.3em;
        text-align: right;
        position: static;
      }
    }
  }
}

.page__cv-remarkable-project {
  display: flex;
  flex-direction: column;
  line-height: 1.42;
  margin-bottom: 33px;
}

.page__cv-remarkable-project-name {
  font-weight: 700;
  margin-bottom: 6px;
}

.form--cv > .modal__error {
  margin-bottom: 10px;
}

.table__status--approved {
  color: $color-status-done;
}

.page__cv-responsible {
  display: flex;
  align-items: flex-start;
  font-size: $small-font-size;
  font-weight: 700;
  line-height: 1.5;
  color: rgba($color-dark, 0.5);
  span {
    margin-left: 4px;
  }
}

.page__cv-updated-date {
  font-size: $small-font-size;
  line-height: 1.16;
  color: rgba($color-dark, 0.5);
  margin-bottom: 2px;
}

.page__cv-updated {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 30px;
}

.page__profile-panel__label-info {
  .label {
    padding-left: 10px;
  }
}

.page__cv_content {
  .error_wrapper {
    text-align: center;
    .error_block {
      color: $color-red;
    }

    .loader {
      margin: 0 auto;
    }
  }
}

.label.cv_table_checkbox {
  label:before {
    top: -8px;
  }

  svg {
    top: -8px;

    &.checkbox {
      top: -5px;
    }
  }
}

.choose_resume {
  .table__head .table__head-column:nth-last-child(2) {
    text-align: end;
    position: absolute;
    right: 0;
    top: 3px;
  }

  .table__head .table__row {
    position: relative;
  }
}

.cv_table {
  .export_button_wrapper {
    position: relative;
    height: 14px;
  }

  .export_button {
    display: flex;
    align-items: flex-end;
    margin-left: 5px;

    button {
      display: flex;
      cursor: pointer;
      color: $color-red;
      font-size: 14px;
      font-weight: 500;
    }

    svg {
      margin-right: 10px;
    }

    .selected-coutner {
      margin-left: 10px;
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      color: rgba(74, 74, 74, 0.6);
    }
  }

  .table__row .table__head-column:first-child(),
  .table__row .table__data:first-child() {
    width: 30px;
    max-width: 30px;
    padding-left: 0;
    padding-right: 0;
  }

  .select-row {
    background: #fafafa;

    & + .table__actions-block {
      background: #fafafa;
    }
  }

  .table__type-link:hover .table__user-name {
    text-decoration: underline;
  }
}

.export_cv_modal {
  .radio-button-wrapper {
    display: flex;

    .form__input-block {
      width: auto;
      margin-bottom: 0;

      label {
        padding-right: 26px;
      }
    }
  }

  &.one-resume {
    .modal__body {
      width: 750px;
    }

    .crop_modal .modal__body {
      width: 400px;
    }

    .form__tabs-content {
      width: 100%;
      padding-left: 0;
    }
  }
}
