.finance-project-modal {
  .form__inputs-subwrapper .form__inputs-subwrapper {
    margin-bottom: 0;
  }

  .rate-block.form__inputs-subwrapper {
    .form__input-block:not(:first-child):not(:last-child) {
      width: 87px;
      margin-right: 6px;

      .select__placeholder {
        margin-left: 0;
      }
    }

    .date-wrapper {
      width: 125px;
      margin-right: 6px;
    }

    .pay-terms-wrapper {
      width: 100px;
    }

    .form__inputs-subwrapper {
      align-items: flex-start;
    }

    label {
      font-size: 13px;
    }
  }
}

.jira-projects-wrapper {
  display: flex;
  flex-wrap: wrap;

  div {
    padding-right: 10px;
    padding-bottom: 5px;
  }
}
