.filter {
  &__search-wrapper {
    position: relative;
    width: calc(100% + 3px);
    height: 36px;
    border-bottom: 1px solid #dddddd;

    .icons.search {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      fill: $color-90;
      @include size(16px);
      cursor: pointer;
    }

    .icons.times-circle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      fill: $color-80;
      right: 12px;
      cursor: pointer;
    }
  }

  &.nested-filter .filter__menu-list {
    overflow-x: hidden;
  }

  &__group {
    padding: 0 !important;
    & &-heading {
      padding: 10px 12px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      color: $color-70;
      margin: 0;
    }
  }

  &__button {
    &-wrapper {
      height: 36px;
      display: flex;
      justify-content: space-between;

      & .filter__option-label {
        width: max-content;
        color: $global-color;
        cursor: pointer;

        &.disable {
          opacity: 0.5;
          cursor: default;
        }

        &:hover:not(.disable) {
          background-color: $color-20;
        }
      }
    }

    &-text {
      cursor: default;
      color: var(--global-color);
    }
  }

  &__search {
    height: 100%;
    width: 100%;
    padding: 10px 14px 10px 40px;
  }

  &.check_box_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bg-input;
    height: 36px;
    border-radius: 18px;
    cursor: pointer;
    padding: 9px;

    .form__checkbox:checked + .form__checkbox-label {
      font-weight: bold;
    }

    .checkbox-label {
      text-transform: none;
      line-height: 1px;
      font-weight: normal;
    }
  }

  &__menu {
    width: fit-content !important;
    min-width: 100%;
    background: darken($white, 1%) !important;
    @include dropdown-list(true);
    margin-top: 0 !important;
    padding: 0 !important;
    top: calc(100% + 8px) !important;
    padding-right: 3px !important;
    z-index: 15 !important;
    &-wrapper {
      @include dropdown-list(true);
      transform: var(--data-transform);
      padding: 4px 3px 4px 0;
      min-width: 100%;
    }

    &-list {
      @include scroll-styles;
      max-height: calc(var(--data-max-height) - 82px) !important;
      display: grid;

      &--is-multi {
        min-width: 299px;
        padding-top: 0 !important;
      }
    }
  }

  &__option {
    @extend .dropdown__list-item;
    color: #4a4a4a !important;
    display: flex !important;
    align-items: center;
    height: 36px;
    padding: 0 !important;
    cursor: pointer !important;

    &-label {
      display: flex;
      align-items: center;
      padding: 0 12px;
      width: 100%;
      height: 100%;

      &-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .icons.filter-nested-arrow {
          position: absolute;
          right: -4px;
        }
      }
    }

    .form__checkbox-block {
      pointer-events: none;
      @include size(16px);
      justify-content: flex-start;
      padding: 0;
      margin-right: 8px;
      margin-bottom: 0;
    }

    .form__checkbox-label {
      color: #323232;
      font-weight: 400;
      height: 16px;
    }

    &--is-focused,
    &:active {
      background-color: $bg-input !important;
    }
    &--is-disabled {
      opacity: 0.5;
      cursor: default !important;
    }
    &--is-selected:not(.filter__option--is-focused) {
      background-color: unset !important;
    }
  }

  &.skills-filter {
    .skill-levels-count {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--global-text-color);
      background-color: var(--global-color);
      @include size(20px);
      border-radius: 4px;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }

    .filter__option {
      &-label-content {
        justify-content: space-between;
        padding-right: 56px;
      }
      .skill-levels {
        width: max-content;
        @include dropdown-list(true);

        &-wrapper {
          width: max-content;
        }

        .list-item {
          display: flex;
          align-items: center;
          padding: 10px 12px;
          height: 36px;
          &:hover {
            background-color: $bg-input;
          }
        }
      }
    }
  }

  &__datepicker-container {
    padding: 10px 36px 10px 14px !important;
  }
}

.filter__control {
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  position: relative;

  &--menu-is-open {
    .select-arrow {
      transform: rotate(180deg);
    }
    .filter__value-container {
      background-color: $bg-filter;
    }
  }

  &:hover .filter__value-container {
    box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
  }
}

.filter__indicators {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.filter__value-container {
  display: flex;
  flex-wrap: nowrap !important;
  align-items: center;
  padding: 10px 36px 10px 14px !important;
  border-radius: $border-radius-smaller;
  max-height: 36px;
  background-color: $bg-input;

  &.filter__value-container--is-multi {
    flex-wrap: wrap !important;

    .filter__placeholder:first-child::after {
      content: 'All';
      margin-left: 3px;
    }
  }

  &.bold {
    font-weight: bold;
  }

  &--has-value.filter__value-container--is-multi {
    padding-top: 5px;

    .filter__placeholder {
      margin: 8px 0;

      &::after {
        display: none;
      }
    }
  }

  .filter__single-value {
    position: static;
    overflow: unset;
    white-space: nowrap;
    transform: unset;
  }

  .filter__multi-value {
    background-color: $bg-input;
    display: none;

    &:last-of-type {
      margin-right: 15px;
    }

    .filter__multi-value__remove {
      .icons {
        @include size(12px);
        color: lighten($gray, 40%);
      }

      &:hover {
        background: unset;

        .icons {
          color: $gray;
        }
      }
    }
  }

  .filter__placeholder {
    display: flex;
    align-items: center;

    &:empty {
      display: none;
    }
  }

  &--with-icon {
    .filter__placeholder {
      margin-left: 4px;
    }
  }

  .css-b8ldur-Input {
    margin: 0;
    padding: 0;
  }
  .multi-select-container {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
  }
}

.filter__candidate .filter__value-container {
  padding: 0 !important;
}

.filter-control {
  .filter__value-container {
    padding: unset !important;
    height: 36px;
    width: 36px;
    position: relative;

    svg {
      position: absolute;
      height: 18px;
      width: 18px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .filter__option-label {
    padding-right: 0;
    position: relative;
    padding-left: 0;

    .dropdown.open + .is-public-icon {
      display: none;
    }

    .filter-control-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 36px;

      span {
        width: 100%;
        margin-right: 46px;
      }
    }

    &:hover {
      .dropdown {
        opacity: 1;
      }
      .is-public-icon:not(.check) {
        display: none;
      }
    }

    .is-public-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 3px;
      transform: translateY(-50%);
      color: #828282;
    }

    .check {
      left: 12px;
    }
  }

  .dropdown {
    position: absolute;
    right: 0;
  }

  .dropdown.open {
    z-index: 1;
  }
  .dropdown:not(.open) {
    opacity: 0;
  }

  .filter__menu {
    min-width: 100px;
  }

  .filter-button {
    display: inline-block;
    padding: 12px;
    width: auto;
  }

  .filter__button-wrapper {
    justify-content: flex-start;
  }

  .filter__group-heading {
    display: flex;
    align-items: center;
    span {
      padding-top: 2px;
    }
  }

  .user {
    margin-right: 8px;
  }

  .filter__option--is-focused {
    background: transparent !important;
  }

  .filter__option:hover {
    background: $bg-input !important;
  }
}

.button.clear-filter-button {
  background-color: $bg-input;
  margin-right: 0;
  color: $color-dark;

  .icons {
    margin-right: 8px;
    width: 18px;
    height: 14px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
}

.filter-control-modal .form__input-block {
  margin-bottom: 17px;
}
