.project_hours_table {
  border-left: none;

  .switch__block {
    margin: 0 auto;
  }

  .table__row:last-child .table__data {
    font-weight: bold;
  }

  .table__row th,
  .table__row td {
    border-right: 1px solid #f0f0f0;
  }

  & > tr.table__row:nth-last-child(2) {
    cursor: auto;
    svg {
      fill: transparent;
      cursor: default;
    }

    .table_button--open-modal {
      cursor: auto;
    }
  }

  .sub_row_table__data {
    position: relative;

    &::before {
      height: 0;
    }
  }
}
