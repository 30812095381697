.datepicker-container {
  &.rigth .daterangepicker:not(.single) {
    left: 0 !important;
    right: unset !important;
  }

  &.left .daterangepicker:not(.single) {
    right: 0 !important;
    left: unset !important;
  }
  .input-buffer {
    position: absolute;
    top: -1000px;
    left: -1000px;
    visibility: hidden;
    white-space: pre-wrap;
    font-size: 14.55px;
  }
}

.daterangepicker {
  color: $gray;
  margin: 0;
  border: none;
  border-radius: 4px;
  font-family: $main-font;
  box-shadow: $dropdown-box-shadow;
  color: #4a4a4a;
  font-weight: 400;
  z-index: 10050;
  width: 100%;
  top: 41px !important;
  min-width: max-content;

  &.show-calendar {
    width: max-content;

    ul {
      width: 144px !important;
    }
  }

  &:not(.show-calendar) {
    .ranges,
    ul {
      width: 100% !important;
    }
  }

  &.single {
    right: 50% !important;
    transform: translateX(50%);
    width: 264px !important;
    min-width: unset;
    .ranges {
      display: none;
    }
    .calendar-table {
      padding: 0 !important;
    }

    .drp-calendar {
      width: 100%;
      padding: 20px !important;
    }
  }

  &.show-calendar .ranges {
    min-height: 265px;
    margin-top: 14px !important;
  }

  & .ranges {
    & li:hover {
      background-color: $bg-hover;
    }
    & ul li {
      padding: 12px 22px !important;
      width: calc(100% - 8px);
      margin: 0 4px;
      position: relative;
      &.active {
        background-color: transparent !important;
        color: #4a4a4a !important;
        &::before {
          position: absolute;
          background-color: var(--dateRangepicker-global-color) !important;
          content: '';
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: -1;
          opacity: 0.1;
        }
      }
    }
  }

  &-input {
    max-width: 500px;
    color: #4a4a4a;
    @include input-styles;
    text-align: center;
    box-sizing: content-box;
  }

  @include media('<lg') {
    width: calc(720px - 30px);
  }
  @include media('<md') {
    width: calc(540px - 30px);
  }
  @include media('<sm') {
    width: calc(100% - 30px);
  }

  &:before,
  &:after {
    display: none;
  }

  .ranges {
    margin: 4px 0;
    @include media('<md') {
      float: unset;
    }

    ul {
      width: 100%;
      @include media('>lg') {
        width: 250px;
      }

      li {
        font-size: $main-font-size;
        padding: 10px 12px;

        &.active {
          background-color: #d1e8f8;
          color: $gray;
        }
      }
    }
  }

  & .drp-calendar {
    max-width: unset;

    &.left {
      border: none !important;
      padding-left: 15px;
    }

    &.right {
      padding-right: 30px !important;
    }

    &.left,
    &.right {
      padding-top: 16px !important;
    }
  }

  .drp-buttons {
    border-top: 1px solid #e7e7e7;
    padding: 25px 30px;
    position: relative;

    .button {
      margin: 5px 0;
      width: 100%;
      padding: 10px 17px 10px 16px;
      display: inline-block;
      @include media('>lg') {
        margin: 0 5px;
        width: 100px;
      }

      &.apply--button {
        background-color: var(--dateRangepicker-global-color);
        color: #fff;
      }

      &.btn-default {
        background-color: $color-20;
      }
    }

    .drp-selected {
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      color: #333333;
      opacity: 0.5;
      padding-right: 45px;
    }

    .button,
    .drp-selected {
      font-size: $main-font-size;
    }
  }

  .calendar-table {
    thead {
      .month {
        font-size: 16px;
        font-weight: 700;
      }

      tr:nth-child(2) {
        text-transform: uppercase;
        opacity: 0.8;

        & th {
          padding-top: 16px;
          color: #bdbdbd;
        }
      }
    }

    & td {
      width: 32px;
      height: 32px;
      padding: 2px;
      border: none;
      line-height: unset;
      position: relative;

      &.ends {
        color: $color-70 !important;
      }
      &.in-range:not(.start-date),
      &.start-date.ends {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
          height: calc(100% - 4px);
          background-color: var(--dateRangepicker-global-color);
          opacity: 0.1;
        }

        &:not(.active) {
          background-color: unset;
        }
      }

      &:last-child::before,
      &.in-range:last-child::before,
      &.end-date.ends::before {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      &:first-child.active:not(.ends)::before {
        content: none;
      }

      &:first-child::before,
      &.start-date.ends::before {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &.active:not(.ends) {
        color: var(--text-global-color);
        background-color: var(--dateRangepicker-global-color);
        background-clip: content-box;
        border-radius: 50%;

        &.start-date + .in-range:before {
          left: -12px;
          width: calc(100% + 12px);
        }

        &.start-date + .in-range.active::before {
          width: 100%;
        }

        &.end-date::before {
          width: calc(100% - 12px);
        }
      }

      &:hover:not(.active):not(.in-range) {
        border-radius: 50% !important;
        background-color: $bg-hover !important;
        background-clip: content-box !important;
      }

      &.active.ends:hover {
        background-color: unset !important;
      }
    }
  }

  &.show-calendar {
    .ranges {
      margin: 0;
      border-right: 1px solid #e7e7e7;
    }
  }
}
