.invoice-modal,
.invoice-modal-clone {
  .modal__body {
    overflow: visible;
    .form__inputs-wrapper {
      overflow: visible;
    }
  }
  &--half-input {
    width: 50%;
    .select.time-select {
      max-width: 100%;
    }
  }
  &--datepicker {
    position: relative;
    margin-bottom: 15px;
    background-color: $bg-input;
    height: 44px;
    .filter__datepicker-container {
      padding: 14px 36px 14px 14px !important;
      .icons.filter-arrow {
        width: 7px;
      }
      &.placeholder {
        opacity: 0.5;
      }
    }
  }
  &-checkbox--row {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    .label {
      width: max-content;
    }
  }
  &--file-input {
    align-items: center;
    justify-content: space-between;
    .recognition__icon-reset {
      font-size: 14px;
      &.disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

.invoice-table__time {
  display: flex;
  gap: 5px;
  align-items: center;
  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
}
.ends-section {
  margin-top: 20px;
}

.ends-section__note {
  font-size: 14px;
  margin-bottom: 10px;
}

.ends-section__option {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.ends-section__input-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1;
}

.ends-section__radio {
  min-width: 80px;
  display: flex;
  align-items: center;
}

.ends-section__label {
  font-size: 14px;
  color: #666;
  white-space: nowrap;
}

.ends-section__option .form__input-block {
  width: auto;
}

.ends-section__option input[type='date'] {
  width: auto;
}

.ends-section__option input[type='number'] {
  width: 75px;
}

.invoice-number-generation-title {
  margin-top: 10px;
}

.invoice-number-input {
  width: 250px;
}

.hidden-invoice-step {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;
  overflow: hidden;
}

.increment-step-checkbox {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 38px;
  line-height: normal;
  margin: 0;
}

.form__checkbox-capitalazie-label {
  display: flex;
  align-items: center;
  margin: 0;
}

.increment-step-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
  margin-left: 8px;
}

.increment-step-container input {
  width: 100%;
  visibility: hidden;
}

.increment-step-container input:not([value='']) {
  visibility: visible;
}

.custom-file-input {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.file-button {
  display: inline-block;
  padding: 6px 12px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, sans-serif;
}

.file-button:hover {
  background-color: #f7f7f7;
  border-color: #bbb;
}

.file-button:active {
  background-color: #eeeeee;
  border-color: #aaa;
}

.file-placeholder {
  flex-grow: 1;
  margin: 0 10px;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.invoice-file-label {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-bottom: 5px;
  display: block;
}

.invoice-amount {
  color: $global-color;
}

.no-amount{
  color: inherit;
}

.invoice-icon {
  margin-left: 5px;
}

.invoice-modal-clone {
  .modal__body {
    overflow: visible !important;

    .form__inputs-wrapper {
      overflow-y: auto;
    }
  }
}

.invoice-modal-clone .daterangepicker {
  position: fixed !important;
  z-index: 9999 !important;
  top: auto !important;
}

.invoice-modal-clone .daterangepicker.ltr.show-calendar.opensleft {
  left: auto !important;
  right: auto !important;
}