.toolbar {
  display: flex;
  width: 100%;
  padding: 20px 5px 5px 5px;
  background-color: $bg-input;
  border-radius: 0 0 $border-radius-input $border-radius-input;
  border: none;

  &.ql-toolbar.ql-snow {
    border: none;
    font-family: $font-family-roboto;
  }
}

.form__textarea {
  width: 100%;
  padding: 22px $input-padding-right 4px;
  @include input-styles;

  &:focus + .form__label,
  &.not-empty + .form__label {
    font-size: $small-font-size;
    line-height: $small-font-size;
  }

  border-radius: $border-radius-input $border-radius-input 0 0;
  max-height: 200px;

  .ql-container {
    border: none;

    .ql-editor {
      padding: 0;

      &.ql-blank:before {
        font-family: $font-family-roboto;
        font-size: 14px;
        line-height: 14px;
        color: $color-light-gray;
        font-style: normal;
        position: absolute;
        left: 0;
      }

      em {
        font-style: italic;
      }
    }
  }
}

.text-editor.with-label {
  .textarea_label.form__label {
    top: 0;
    font-size: 12px;
    line-height: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $bg-input;
    z-index: 2;
  }
}

.text-editor .ql-disabled .ql-editor {
  color: rgba(74, 74, 74, 0.6);
}

.text-editor {
  .textarea_label {
    border-radius: 4px;
  }

  .form__textarea-wrapper {
    border-radius: 4px;
    background-color: $bg-input;
  }

  .form__textarea {
    border-radius: unset;
    background-color: transparent;

    .ql-container.ql-snow {
      border: none;
    }
  }
}
