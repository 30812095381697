.icons {
  display: inline-block;
  vertical-align: baseline;
  stroke: currentColor;
  fill: currentColor;

  @include size(1em);

  stroke-width: 0;

  &.recognition-icons {
    @include size(1em);
    margin-right: 10px;

    &.without-icon {
      border-radius: 50%;
    }

    svg {
      @include size(100%);
      fill: currentColor;
    }

    &-preview {
      @include size(110px);
      min-width: 110px;
      padding: 15px;
      border-radius: 18px;
      border: 1px solid $border-setting-color;
      background-color: currentColor;
      fill-opacity: 0.2;
      svg {
        @include size(100%);
      }
    }
  }
  &.send-message {
    height: 100%;
    width: 100%;
    fill: #c1201e;
  }

  &.menu-link-icons {
    @include size(16px);
    margin-right: 12px;

    img {
      @include size(100%);
    }
  }

  &.disabled-chackbox {
    fill: $global-color;
    border-radius: 3px;
  }

  &.filter-arrow {
    width: 10px;
    height: 7px;
  }

  &.angle-right,
  &.angle-left {
    width: 5.33px;
    height: 9.33px;
  }
}

.icon {
  display: inline-block;
  vertical-align: baseline;
  stroke: currentColor;
  fill: $color-dark;
  stroke-width: 0;
}
