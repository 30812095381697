.form__color-block {
  @include size(20px);
  position: relative;

  &.form__input-color {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 1;
  }
}

.form__color-input {
  @include size(100%);
  visibility: hidden;

  &:disabled + label {
    pointer-events: none;
  }
}

.form__color-label {
  display: block;
  @include size(100%);
  @include position(absolute, 0 null null 0);
  border: 1px solid $border-setting-color;
  border-radius: $border-radius-smaller;
  background: $main-bg;
  cursor: pointer;
}

.color-input-circle {
  position: absolute;
  top: 15px;
  right: 14px;
  width: 15px;
  height: 15px;

  label {
    border: none;
    border-radius: 50%;
  }
}
