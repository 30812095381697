.tracker-description {
  margin-bottom: 30px;
  font-size: 16px;
}

.task-tracker-software-page-panel.page__profile-panel {
  padding-bottom: 21px;
}

.software-links-block {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.software-link {
  background-color: $bg-input;
  border-radius: 4px;
  height: 180px;
  padding: 108px 30px 30px 30px;
  width: 198px;
  margin-bottom: 12px;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  line-height: 16.5px;

  cursor: pointer;
  position: relative;

  &:not(:nth-child(6n)) {
    margin-right: 12px;
  }

  .delete-software-button {
    position: absolute;
    right: 14px;
    top: 14px;
    display: none;
    cursor: pointer;

    .icons {
      width: 18px;
      height: 18px;
      color: #4a4a4a;
    }
  }

  &:hover {
    box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);

    .delete-software-button {
      display: block;
    }
  }

  & > .icons,
  .loader {
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);

    height: 41px;
    width: 48px;

    &.windows {
      height: 48px;
      width: 48px;
    }
  }
}

.task-tracker-software-page .error_wrapper {
  text-align: center;
  .error_block {
    color: $color-red;
  }

  .loader {
    margin: 0 auto;
  }
}
