.table {
  width: 100%;

  &.fixed {
    table-layout: fixed;
  }

  &.loading {
    display: none;
  }

  &__dropdown-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &--employee-groups {
    .table__data {
      &:nth-child(1) {
        font-weight: 500;
      }
    }
  }

  &--offices,
  &--projects,
  &.follow-ups-table,
  &--mailer-settings {
    .table__head-column,
    .table__data {
      &:nth-child(1) {
        font-weight: bold;
      }
    }

    .table__data__btn {
      width: 100%;
      line-height: $main-font-size;
      font-weight: bold;
      text-align: left;
      cursor: pointer;
    }
  }

  &--bordered-opener {
    @include bordered-opener;
  }

  &--bordered-top {
    .table__row:nth-child(1) .table__head-column {
      border-top: $table-border;
    }
  }

  &.technical-skills {
    td:first-child {
      width: 100%;
    }
  }

  .table__options {
    font-weight: 500;
    color: rgba($color-dark, 0.6);
  }

  &--hierarchical {
    .table__head-column {
      &--fixed-left {
        border-right: 1px solid $border-color;
      }

      &--fixed-left + .table__head-column:not(.table__head-column--fixed-left) {
        border-left: unset;
      }

      &:not(.table__head-column--fixed-right) + .table__head-column--fixed-right {
        border-left-width: 2px;
      }

      &.content-center {
        text-align: center;
      }
    }

    .text {
      max-width: 250px;
    }

    .table__body .table__row,
    .table__row-title {
      min-height: $body-row-height;
    }

    .table__row {
      border: none;

      &.even .table__data {
        background-color: $bg-table-row;
        background-clip: padding-box;
      }

      &:not(.table__row--opener) .table__row-title {
        padding-left: 10px;
      }

      &--total .table__data:nth-child(1) {
        padding-left: 32px;
      }

      &--total .table__data,
      &--total .table__row-title {
        font-weight: bold;
      }

      &:last-child:not(.table__row-head) {
        &.show-last-devider .table__data {
          border-bottom-color: $table-hierarchical-border-devider;
        }
        .table__data {
          border-bottom: 1px solid $border-color;
        }
      }
    }
    .table__data.first-column {
      padding-left: var(--column-padding-left);
    }

    .table__data {
      position: relative;
      padding-top: 0;
      padding-bottom: 0;

      &::before {
        @include hierarchical-table-border;
      }
      &:first-child::before {
        width: calc(100% - var(--column-padding-left));
      }

      &--fixed-left + .table__data:not(.table__data--fixed-left) {
        border-left: unset;
      }

      &:not(.table__data--fixed-right) + .table__data--fixed-right {
        border-width: 2px;
      }
    }
  }

  .third_level_sub_row_table__data {
    &:nth-child(1) {
      &::before {
        width: calc(100% - 100px);
      }
    }
  }

  &.table--half {
    width: 50%;
  }

  &.absence-periods-table .table__head-column {
    &:nth-child(1),
    &:nth-child(2) {
      width: 25%;
    }
  }

  &.employees-issue-types {
    .table__head-column:first-child() {
      width: 40%;
    }
  }

  .name-capital-letter-block {
    border-radius: 50px;
    background: $color-clients-light-gray;
    position: relative;
    min-width: 22px;
    width: 22px;
    height: 22px;
    margin-right: 8px;
    font-weight: 500;

    span {
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      color: $white;
    }

    & + div {
      padding-top: 3px;
    }
  }

  .jira-project-link:hover {
    text-decoration: underline;
  }

  &.active-and-inactive-hours-table {
    .table__data:not(:last-child),
    .table__head-column:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }

  .tabs__content-item__about-info-value {
    max-width: 350px;
    &__subitem {
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.table__body {
  &:not(.sortable-table) .table__row.hasHover:hover td,
  &.sortable-table .table__row.hasHover.sortable-chosen td {
    background-color: $table-hover !important;
    background-clip: padding-box;
  }

  &.sortable-table .table__data.table__sort:hover {
    background-color: unset;
  }

  &.show-divider {
    border-color: $table-hierarchical-border-devider;
  }
}

.table__row {
  border-bottom: $table-border;
  position: relative;

  .arrow-open-sub-row {
    transform: rotate(90deg);
  }

  &--opener {
    cursor: pointer;

    .table__data {
      border: none;
      &:first {
        padding: 0;
      }
    }

    .table__data,
    .table__row-title {
      font-weight: bold;
    }

    .table__data--normal {
      font-weight: normal;
    }

    &.open {
      .table__data,
      .table__row-title {
        &::before {
          background-color: $table-hierarchical-border-devider;
        }
      }
      .table__open-icon {
        transform: rotate(90deg);
      }
    }
  }

  &--total .table__data:nth-child(1)::before {
    @include hierarchical-table-border;
  }

  &.hidden {
    display: table-row;

    .table__data {
      padding-top: 0;
      padding-bottom: 0;
      height: 0 !important;
      line-height: 0;
      overflow: hidden;

      div,
      button,
      span,
      img,
      td {
        min-height: 0;
        height: 0 !important;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
      }
    }
  }

  &.show-divider {
    .table__data,
    .table__row-title {
      &::before {
        background-color: $table-hierarchical-border-devider;
      }
    }
  }
}

.table__head {
  border-top: $table-border;
}

.table__head-column {
  font-size: $small-font-size;
  line-height: $main-font-size;
  font-weight: bold;
  color: rgba(74, 74, 74, 0.6);
  text-align: left;
  padding-top: 7px;
  padding-bottom: 9px;

  &--opacity {
    color: $color-dark;
  }

  &--dropdown,
  &--grip {
    width: 28px;
    max-width: 28px;
  }

  &--days {
    width: 6.18%;
  }

  &--competencies-name {
    width: 42.45%;
  }

  &--office-name {
    width: 41.45%;
  }

  &--competence-skills {
    width: 62.63%;
  }

  &.competence-max-score,
  &.competence-questions {
    width: 200px;
  }

  @include event-colors;
}

.table-sort {
  @include size(10px);
}

.ascending-order {
  transform: rotate(180deg);
}

.table__button {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(14px);
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: inherit;
}
.table__type-link {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 500;
  color: $color-dark;

  &--bold {
    font-weight: bold;
  }

  &.text-right {
    justify-content: flex-end;
  }

  &:hover:not(.link-disabled):not(.resume-info-link):not(.candidate-link) {
    text-decoration: underline;
  }

  &.candidate-link:hover .candidate-name {
    text-decoration: underline;
  }
}

.table__sort {
  cursor: pointer;
  &:hover {
    background: $table-hover;
    background-clip: padding-box;
  }
}

.table-sort {
  vertical-align: middle;
}

.table_button--open-modal {
  cursor: pointer;
}

.table__data__btn {
  color: $color-dark;
}

.table__no-data {
  caption-side: bottom;
  padding-top: 25px;

  &.no-data:before {
    content: 'No data';
  }

  &.users-no-data::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.loading {
    padding-top: 25px;
    display: block;

    &:before {
      content: 'Loading...';
    }
  }

  &.error:before {
    content: 'Failed to load data';
    color: $color-red;
  }

  &.hidden:not(.loading) {
    display: none;
  }
}

.grip-vertical {
  cursor: grabbing;
  opacity: 0.6;
  @include size(16px);
}

.table__data-image {
  @include size(18px);
  border-radius: 50%;
  margin-right: 5px;
}

.table__data-no-image {
  @include size(18px);
  min-width: 18px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: rgba($color-light-gray, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-light-gray;
  font-size: 0.55rem;
  user-select: none;

  &:hover {
    text-decoration: none;
  }
}

.table__data-wrapper {
  display: flex;
  align-items: center;

  &.content-center {
    justify-content: center;
  }

  &.bold {
    font-weight: 700;
  }
}

.table__head-column,
.table__data {
  vertical-align: middle;
  padding-right: 10px;
  padding-left: 10px;

  &--bold {
    font-weight: bold;

    .table__data__btn {
      font-weight: bold;
    }
  }

  &--black {
    font-weight: 900;
  }

  &--center {
    text-align: center;
  }

  &--bottom {
    vertical-align: bottom;
  }

  &--no-border {
    border-top: unset;

    &::before {
      display: none;
    }
  }

  &--empl {
    padding-top: 20px;
    padding-left: 0;
  }
}

.table__open-icon {
  @include open-icon;
  min-width: 18px;
}

.table__row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 10px 11px 6px;
  line-height: 16px;
}

.table__data {
  line-height: 18px;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 9px;

  &--right {
    text-align: right;
  }

  & .table__subitem {
    font-size: 12px;
    line-height: 12px;
    color: $color-70;
  }

  @include event-colors;

  &--sick,
  &--vacation,
  &--day-off,
  &--trip {
    &:not(.table__data--fixed-right) {
      font-weight: bold;
    }
  }

  &--trainee {
    color: $color-comp-lvl-trainee;
  }

  &--junior {
    color: $color-comp-lvl-junior;
  }

  &--middle {
    color: $color-comp-lvl-middle;
  }

  &--senior {
    color: $color-comp-lvl-senior;
  }

  &--alert {
    position: relative;

    &:hover {
      .table__popover {
        display: flex;
      }
    }
  }

  &--active {
    color: $color-bonuses-td-active;
  }

  &--permiss-checkbox {
    padding-top: 12px;
  }

  &.available {
    color: $color-bonuses-td-active;
    background: rgba($color-bonuses-td-active, 0.08);
    font-weight: 900;
  }
}

.page__scrollable-table-wrapper__inner {
  overflow: auto;

  @include scroll-styles;

  &.event-departments-wrapper {
    position: relative;
    max-height: calc(100vh - 280px);

    .table__no-data {
      display: block;
      position: unset;
      left: unset;
      transform: unset;
      padding-bottom: 1px;
    }

    &::-webkit-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: none;
    }
  }

  &.activity-table-wrapper::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: none;
  }
}

.table__data.table__head-column--fixed-left,
.table__head-column.table__head-column--fixed-left {
  position: absolute;
  z-index: 2;
}

.table__data.table__head-column--fixed-right,
.table__head-column.table__head-column--fixed-right {
  position: absolute;
}

.page__scrollable-table-wrapper {
  width: 100%;
  position: relative;

  caption {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.icons.users {
  width: 17px;
  height: 17px;
  margin-right: 6px;
}

.table__participants,
.table__list-data {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.table__user {
  display: flex;
  align-items: center;
  color: $color-dark;
  position: relative;
  margin-right: 8px;

  &.table_image_width {
    width: 24px;
  }

  .candidate-name {
    white-space: nowrap;
  }

  &-name.tooltip {
    @include popover;
    justify-content: center;
    white-space: nowrap;
    padding: 2px 5px;
    margin: 0;
    font-weight: bold;

    &.__react_component_tooltip::before,
    &.__react_component_tooltip::after {
      content: none !important;
    }
  }
}

.table__user-avatar {
  width: 18px;
  height: 18px;
  @include avatar-styles;

  &--no-img {
    @include avatar-without-img;
  }
}

.table__user-avatar-select {
  padding: 0 !important;
}

.table__more-users {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  width: auto;
  height: 18px;
  border-radius: 50%;
  background: $border-color;
  padding: 0 5px 0 3px;
  font-size: $xxsmall-font-size;
  font-weight: 500;
}

.table--striped .table__head-column,
.table--striped .table__data:not(.bonus-value) {
  border-left: 1px solid $border-color;
}

.table--striped .table__head-column:nth-child(1),
.table--striped .table__data:nth-child(1) {
  border-left: unset;
}

.table--striped
  .table__head
  .table__row:nth-child(1)
  .table__head-column:nth-child(1):not(.table__head-column--fixed-left) {
  border-right: 1px solid $border-color;
}

.table--bonuses,
.table--bonuses-by-grades {
  & .table__grade {
    width: 5.8542%;
    padding-top: 1px;
    padding-bottom: 1px;

    &:last-child {
      border-right: 1px solid $border-color;
    }
  }

  & .table__head .table__row {
    height: 32px;
    & .table__head-column:first-child {
      width: 284px;
    }
  }
  &:not(.table--bonuses-by-grades) .sub_row_table__data .table__row-title {
    border-right: 1px solid $border-color;
  }
}

.table__data.sub_row_table__data .grade-bonus {
  display: flex;
  justify-content: center;
}

.table__data-wrapper .bonus-title {
  max-width: 700px;
  word-break: break-word;
}

.table__head-column.available {
  color: #f7a738;
  background: rgba(247, 167, 56, 0.08);
  font-weight: 900;
}

.table--bonuses-by-grades {
  & .bonus-category-active {
    color: $color-status-done;
  }

  & .bonus-category-inactive {
    color: $color-dark;
  }

  & .bonus-category-inactive,
  & .bonus-category-active {
    position: absolute;
    right: 30px;
    font-weight: normal;
  }

  .bonus__category-action-wrapper {
    position: relative;
    min-height: 18px;
    display: flex;
    justify-content: flex-end;
  }
}

.bonuses-table .table__data:last-child {
  width: 38px;
}

.table--striped.table--bonuses-by-grades .table__row .table__data:not(.bonus-value, .bonus-by-grades__action) {
  border-left: unset;
  & .dropdown {
    display: flex;
    justify-content: flex-end;
  }
}

.table.table--bonuses-by-grades.table--striped {
  & .bonus-grades-title {
    border-bottom: $table-border;
    width: 100%;
  }

  &:not(.user-tabs) .table__row-title {
    max-width: calc(100% - 45px);
  }

  & .table__row .table__data:last-child {
    min-width: 40px;
    border-left: unset;
  }

  & .table__data.bonus-value {
    text-align: center;

    & .bonus-value-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    & .table__data-icon {
      width: 12px;
      height: 12px;
      margin-right: 2px;
      fill: #6a9e3f;
    }
  }

  .table__row:not(.table__row--opener) .table__data:not(.first-column) {
    &.bonus-value,
    &.sub_row_table__data {
      border-left: 1px solid $border-color;
    }

    &:nth-child(2) {
      border-left: 2px solid $border-color;
    }
  }

  .bonus-grades-title {
    border-left: none;
  }

  .table__head .table__row .table__head-column.table__head-social {
    border-right: 2px solid $border-color;
  }

  &.user-tabs {
    .table--hierarchical.table__data::before {
      display: none;
    }
    .table__grade:last-child {
      border-right: unset;
    }

    .table__data-wrapper.user-bonus-row {
      width: 100%;
      justify-content: space-between;

      .table_button--open-modal {
        max-width: 45%;
        word-break: break-word;
      }

      .active-bonus {
        color: #6a9e3f;
        max-width: 50%;
        word-break: break-word;
      }
    }
  }
}

.schedule__departments-event {
  &-table {
    width: max-content;
    table-layout: fixed;
    position: relative;

    .table__head-column {
      color: $color-dark;
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      border-right: 1px solid #f0f0f0;
    }

    .table_button--open-modal,
    .table__row-title span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.table__user-fullName {
        margin-right: 5px;
      }
    }
    .table__user-absence {
      &-icon {
        opacity: 0.5;
      }
      &-btn {
        cursor: pointer;
        @include size(12px);
      }
    }

    &.table--striped .table__head .table__row .table__head-column.sticky:nth-child(1) {
      border-right: none;
    }

    .sticky {
      position: sticky;
      left: 0;
      background-color: #fff;
    }

    .table__row.open .table__data::before,
    .table__row.show-divider .table__data:before {
      content: '';
    }

    .table__data {
      min-width: var(--column-width);
    }

    .table__row {
      display: flex;
      padding-left: 0 !important;

      &.hidden:not(.visible) {
        display: none;
      }

      .table__data:first-child {
        position: sticky;
        z-index: 2;
        left: 0;
        background-color: #fff;
        min-width: 300px;
        width: 300px;
        display: flex;

        .table__row-title,
        .table__data-wrapper {
          width: 100%;
        }
      }
    }

    .table__head {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 3;

      .table__row:nth-child(2) {
        display: none;
      }

      &-column.sticky {
        width: 300px;
        min-width: 300px;
        border-right: none;
      }

      &-column.dates {
        width: var(--column-width);
        min-width: var(--column-width);

        &.holiday {
          color: $calendar-holiday;
        }

        &:last-child {
          border-right: 1px solid #f0f0f0;
        }
      }
    }
    .event-data {
      vertical-align: top;
      position: relative;
      width: var(--column-width);
      padding-top: 4px;
      padding-bottom: 4px;

      &:last-child {
        overflow-x: hidden;
      }

      .event-row {
        min-height: 22px;
        min-width: 22px;
        border-radius: 11px;
        padding: 4px 0 4px 6px;
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        color: #fff;
        margin-bottom: 4px;

        &-text {
          white-space: nowrap;
          overflow-x: hidden;
        }

        &:last-child {
          margin: 0;
        }

        &.data {
          z-index: 1;
          position: relative;
          cursor: pointer;
        }
      }

      .event-department-wrapper {
        width: var(--column-width);
      }
    }
  }
}

.custom-navigation,
.custom-header-navigation {
  & .table__data.link-icon {
    width: 48px;

    & .icon-wrapper {
      display: flex;
      justify-content: center;
      max-width: 16px;
      max-height: 16px;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.custom-navigation {
  & .table__data.link-name {
    width: 10%;
  }

  & .custom-menu-link {
    color: $global-color;
    &:hover {
      text-decoration: underline;
    }
  }
}

.custom-header-navigation {
  table-layout: fixed;

  & .table__data.table__sort {
    width: 40px;
    box-sizing: border-box;
    padding-right: 0;
  }
}

.follow-ups-table {
  .recipient-table {
    display: flex;
    flex-wrap: wrap;
    .table__data-wrapper {
      flex-wrap: wrap;
      row-gap: 3px;
    }
  }

  .recipient-email:hover {
    text-decoration: underline;
  }

  .recipient-user {
    .page__profile-block-avatar.user-page-link:hover {
      transform: scale(1.1);
    }
  }
}

.table .table__data .data__enum {
  width: max-content;
  margin-right: 7px;
  position: relative;
  background: $bg-input;
  padding: 3px 5px;
  margin: 0 3px 3px 0;
  border-radius: 11px;
}

.table-with-last-total-item {
  & > tr.table__row:nth-last-child(2) {
    cursor: auto;
    svg {
      fill: transparent;
      cursor: default;
    }

    .table_button--open-modal {
      cursor: auto;
    }

    td div {
      font-weight: bold;
    }
  }
}

.table.permissions-table {
  .table__data-wrapper {
    justify-content: center;
  }
  .table__head-column:nth-child(1) {
    width: 240px;
  }
}

.currencies-table {
  .table__head-column:nth-child(2) {
    width: 50%;
  }
}

.suppliers-table {
  .table__head-column:nth-child(1) {
    width: 50%;
  }
}

.expense-types-table {
  .table__head-column:nth-child(2) {
    width: 130px;
  }
}

.active-status {
  color: $color-status-done;
}

.inactive-status {
  color: $color-dark-transparent;
}

.table-candidate {
  .table__data {
    vertical-align: top;
    padding-top: 15px;
    padding-bottom: 17px;
  }
}
