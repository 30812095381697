.form__radios-wrapper {
  width: 100%;
  display: flex;
  border-radius: $border-radius-smaller;
  background: $bg-input;
  padding: 2px;
  margin-bottom: 30px;

  & + .form__inputs-wrapper .form__inputs-subwrapper {
    flex-direction: column;
    margin-bottom: 16px;

    .form__input-block {
      margin-right: 0;
      margin-bottom: 12px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}

.form__radio-block {
  width: 50%;
}

.form__radio {
  display: none;

  &:checked + .form__radio-label {
    background: $main-bg;
    box-shadow: $dropdown-box-shadow;
    font-weight: bold;
  }
}

.form__radio-label {
  @include size(100%, 36px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include text-styles;
  line-height: 16px;
  border-radius: $border-radius-smaller;
  cursor: pointer;
  transition: $main-transition;

  .form__radio-label-text {
    display: flex;
  }
}

.form__radio-icon {
  fill: $color-dark;
  @include size(14px);
  margin-right: 6px;
}
