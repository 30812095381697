.info-block {
  &__wrapper {
    margin-left: 28px;
    &:first-child {
      margin-left: 0;
    }

    &.green {
      color: $info-block-green;
    }

    &.red {
      color: $info-block-red;
    }

    &.blue {
      color: $info-block-blue;
    }

    &.text-end {
      text-align: end;
    }
    &.text-center {
      text-align: center;
    }
  }

  &__label {
    font-weight: 400;
  }

  &__value {
    font-weight: 700;
  }
}
