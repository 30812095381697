.salary-modal {
  .form__inputs-subwrapper {
    align-items: flex-start;
  }

  .total-input {
    opacity: 1;
    background-color: $white;

    + .form__label {
      opacity: 1;
    }
  }
}

.salary-data {
  display: block;
  position: relative;

  .total-block {
    text-align: center;
    font-weight: 500;
  }

  .pay-tax-block {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    font-weight: 400;

    div:first-child {
      margin-right: 8px;
    }
  }
}

.totla-row-item {
  font-weight: 700;
  color: rgba(74, 74, 74, 0.6);
}

.salaries-table {
  .table__row:nth-child(2n) {
    .table__data {
      background-color: #f9f9f9;
    }
  }

  .table__data,
  .table__head-column {
    border-right: 1px solid $border-color;

    &:first-child() {
      border-left: 1px solid $border-color;
    }
  }

  .table__dropdown-wrapper {
    display: block;
    height: 100%;
  }

  .dropdown__button {
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }

  .dropdown__list {
    border-radius: 8px;

    &.bottom {
      right: -10px;
      top: calc(100% + 10px);
    }

    &.top {
      bottom: calc(100% + 13px);
      right: -10px;
      top: unset;
      transform: none;
    }

    &.left {
      left: -9px;
    }
  }
}

.salaries-table-wrapper {
  margin-left: 250px;
  margin-right: 310px;
  width: calc((100% - 250px) - 150px);

  .table__head {
    border-top: 0;
  }

  .table__data {
    height: 58px;
  }

  .table__head-column {
    height: 54px;

    &:nth-last-child(2) {
      border-right: none;
    }
  }

  .table__row {
    position: static;
  }

  .table__head-column {
    position: relative;
  }

  .salaries-table .table__data,
  .table__head-column:not(.header_row) {
    &:first-child() {
      position: absolute;
      left: 0;
      padding-top: 12px;
      padding-bottom: 9px;
      width: 251px;
      border-bottom: 1px solid $border-color;
    }

    &:last-child():not(:first-child()) {
      position: absolute;
      right: 0;
      padding-top: 12px;
      padding-bottom: 9px;
      width: 150px;
      border-bottom: 1px solid $border-color;
      border-left: 1px solid $border-color;
    }

    &:nth-last-child(2) {
      border-right: none;
    }
  }

  .table__row .table__head-column:first-child() {
    padding-top: 6px;
  }

  .header_row {
    border-bottom: none;
    height: 30px;
    border-top: 1px solid $border-color;
  }

  .employees-column-head {
    position: absolute;
    left: 0;
    padding-top: 12px;
    padding-bottom: 9px;
    width: 251px;
  }

  .total-column-head {
    position: absolute;
    right: 0;
    padding-top: 12px;
    padding-bottom: 9px;
    width: 150px;
    border-bottom: 1px solid $border-color;
  }

  .table__row-head {
    border-top: none;
  }

  .year-display {
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
  }

  .dropdown {
    width: 100%;
    height: 100%;
  }

  .dropdown__button {
    padding: 12px 10px 9px;
    border-radius: 0;
  }

  .table__row .salary-cell {
    padding: 0px;
  }

  .employee-not-hired {
    width: 100%;
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 3px;
      background: #4a4a4a;
      transform: translate(-50%);
    }
  }
}

.current-month {
  color: $global-color;
}

.salary-delete-modal {
  .data-block {
    margin-bottom: 22px;

    &.flex-block {
      display: flex;

      & > div {
        margin-right: 22px;
      }
    }
  }

  .label-block {
    font-size: 12px;
    color: $color-dark;
    opacity: 0.5;
    margin-bottom: 4px;
  }
}
