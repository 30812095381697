.resources_page {
  & + .page__content {
    .event-type-name {
      text-align: center;
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
}

.workload_table {
  width: auto;
  font-size: 12px;

  .workload-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .table__data-value {
    pointer-events: none;
  }

  .workload-disabled {
    box-shadow: inset 200px -200px 300px 200px rgba(255, 255, 255, 0.8);
    color: #0006;
  }

  .table__head {
    border-top: none;
  }

  .table__row:last-child {
    .table__head-column--fixed-right {
      font-weight: 700;
    }

    .first-column {
      border-bottom: 1px solid $border-color;
      color: $color-70;
      font-weight: 700;
    }
  }

  tbody .table__row:last-child .table__head-column--fixed-right {
    border-bottom: 1px solid $border-color;
  }

  .table__row .table__head-column--fixed-right:last-child {
    border-right: none;
  }

  .table__head-column {
    padding-top: 14px;
  }

  .table__data.first-column,
  .table__head-column.employees_column {
    display: flex;
    left: 0;
    width: 250px;
  }

  .table__data.first-column {
    position: absolute;
    z-index: 2;
  }

  .table__row.even {
    background-color: $bg-table-row;

    .first-column,
    .table__head-column--fixed-right {
      background-color: $bg-table-row;
    }
  }

  .sub_row_table__data {
    &::before {
      height: 0;
    }
  }
  .table__row:not(.resources_table_header) .table__head-column {
    min-width: 38px;
    text-align: center;
    &:first-child {
      display: flex;
      align-items: center;
      top: 0;
      border-top: 1px solid $border-color;
      height: 80px;
    }
  }

  .switch__block {
    margin: 0 auto;
  }

  .table__row th,
  .table__row td {
    border-right: 1px solid $border-color;
  }

  & > tr:nth-last-child(2).table__row {
    cursor: auto;
    td {
      border-bottom: 1px solid $border-color;
    }
    svg {
      fill: transparent;
      cursor: default;
    }

    .table_button--open-modal {
      cursor: auto;
    }
    td div {
      font-weight: bold;
    }
  }

  .border-bottom {
    border-bottom: 1px solid $border-color;
  }

  .resources_table_header {
    th {
      border-top: 1px solid $border-color;
      color: #4a4a4a;
      text-align: center;
    }

    th:nth-child(2) {
      width: 100%;
      text-align: start;
    }

    .word-wrap {
      white-space: pre-line;
      box-sizing: border-box;

      &::before {
        content: 'Work days';
        display: block;
        position: absolute;
        left: 0;
        top: 14px;
        height: 42px;
        z-index: 2;
      }
    }

    .table__head-column--fixed-left {
      background: #fff;
    }
  }

  .red {
    color: #f00;
  }

  .weight-normal {
    font-weight: normal;
  }

  .thisDay {
    background-color: rgb(20, 19, 97);
    color: #fff;
    font-size: 14px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .table__row .no-border-right {
    border-right: none;
  }

  .table__head-column:not(.table__head-column--fixed-right) + .table__head-column--fixed-right {
    border-left-width: 1px;
  }

  .table__row {
    position: static;
  }
  .day-display {
    position: relative;

    .month-year-display {
      position: absolute;
      top: -36px;
      left: 0;
      width: 70px;
      color: #4a4a4a;
      background-color: #fff;
    }
  }

  th:nth-child(3) {
    .month-year-display {
      left: 25px;
    }
  }
}

.new_resource_modal {
  .select__control,
  button,
  input[type='date'] {
    cursor: pointer;
  }

  .date_block {
    margin-bottom: 24px;
  }

  .project_block {
    margin-bottom: 12px;

    .form__inputs-subwrapper {
      margin-bottom: 0;
    }

    .form__btn-clean-inputs {
      margin-right: 10px;
      margin-left: 5px;
    }
  }

  .form__inputs-wrapper {
    margin-bottom: 30px;
  }

  .form__inputs-subwrapper {
    align-items: flex-start;

    .form__input-block:first-child {
      margin-right: 16px;
    }
  }

  .without_label {
    .select__value-container {
      padding: 0 0 0 8px !important;
      cursor: pointer;
    }
  }

  .form__buttons {
    align-items: baseline;

    .select {
      margin-right: 20px;
      margin-bottom: 0;
      height: 34px;
      min-width: 189px;

      .select__value-container {
        height: 34px;
        min-height: 34px;
      }
    }

    .form__inputs-subwrapper {
      justify-content: flex-end;

      .button:first-child {
        margin-right: 16px;
      }
    }
  }

  .percent_block {
    width: 118px;

    &.disabled {
      @include disabled();
    }

    input {
      padding-top: 5px;
      padding-left: 12px;
      padding-right: 35px;
      overflow: hidden;

      &.three_symbols + .form__label {
        left: 36px;
      }

      &.two_symbols + .form__label {
        left: 28px;
      }
    }

    .form__label {
      color: #4a4a4a;
      left: 20px;
      font-size: 1em;
      line-height: 1em;
      top: 16px;
      padding-left: 0;
      width: auto;
      pointer-events: none;
    }
  }
}

.resources_table_wrapper {
  margin-left: 250px;
  margin-right: 310px;
  width: calc((100% - 250px) - 310px);
  .table__data {
    height: 42px;
  }
  th {
    height: 38px;
    vertical-align: middle;
  }

  .dayWorkloads:not(.employee-not-hired) {
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
    padding: 0;
    position: relative;
    cursor: default;

    .table__data-wrapper,
    .table__data-value {
      height: 100%;
      max-height: 42px;
    }

    &:hover {
      font-weight: bold;
      box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.15);
    }
  }

  .dayWorkloads .table__data-wrapper {
    padding: 0;

    .table__data-value {
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 14px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      vertical-align: middle;
    }
  }

  .dayWorkloads .table__data-holiday {
    padding-left: 10px;
    padding-right: 10px;
  }

  .dayWorkloads .table__data-employee-not-hired {
    width: 100%;
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 3px;
      background: #4a4a4a;
      transform: translate(-50%);
    }
  }

  .plan_fact {
    .table__sub-row {
      .first-column .table__row-title {
        height: 100%;
      }

      .table__data {
        height: 82px;
      }

      .table__data-wrapper {
        max-height: 82px;
      }
    }

    .fact-workload-wrapper {
      height: 42px;
    }

    .table__data-holiday.table__data-value {
      height: 100%;
      max-height: unset;
      padding-right: 10px;
      padding-left: 10px;
      position: relative;
      min-width: 42px;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .table__row {
      .sub_row_table__data:nth-child(1),
      .sub_row_table__data:nth-last-child(-n + 5) {
        height: 84px;

        & > div {
          height: 84px;
        }
      }

      .sub_row_table__data:nth-last-child(-n + 5) {
        padding-top: 0;
      }
    }

    .table__data-value.workload-full-height {
      max-height: 82px;
    }

    .workload-bold {
      font-weight: 900;
    }
  }

  th.header_row {
    height: 42px;
  }

  .resources_table_header .table__head-column--fixed-right {
    top: 0;
  }

  .table__head-column--fixed-left,
  .table__head-column--fixed-right {
    background: #fff;

    .table__data-wrapper {
      height: 100%;
    }
  }

  .worked_hours_column {
    right: 0;
    width: 80px;
    padding-right: 0;
  }

  .worked_days_column {
    right: 80px;
    width: 49px;
  }

  .planned_hours_column {
    right: 129px;
    width: 80px;
    padding-right: 0;
  }

  .planned_days_column {
    right: 209px;
    width: 49px;
  }

  .work_days_colum {
    right: 258px;
    width: 53px;
    border-left: 1px solid $border-color;
  }

  .worked_hours_header {
    width: 129px;
    right: 0px;
  }

  .planned_month_header {
    width: 129px;
    right: 129px;
  }

  .work_days_header {
    width: 53px;
    right: 340px;
  }

  .alert {
    width: 16px;
    height: 14px;
  }

  .table__data-alert {
    background-color: #ffeaea;
    padding-bottom: 10px;
  }

  .table__data-multi-group {
    background-color: #facdcd;
  }
}

.resource-info-modal {
  .table__user-name {
    margin-left: 5px;
  }

  .modal__grid__item {
    margin-bottom: 20px;

    &:last-child {
      .modal__wrapper {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      margin-bottom: 0;
    }
  }
}
