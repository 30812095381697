.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-red;
  border-radius: $border-radius-smaller;
  padding: 10px 17px 10px 16px;
  color: var(--button-text-color);
  font-weight: bold;
  line-height: 16px;
  max-height: 36px;
  cursor: pointer;
  position: relative;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    box-shadow: inset 200px -200px 300px 200px rgba(0, 0, 0, 0.08);
  }

  &--accent-text {
    background-color: $color-20;
  }

  &--with-icon {
    .button__icon:not(.select-arrow) {
      margin-right: 6px;
    }
    &.btn-select {
      min-width: 104px;
      justify-content: start;
    }
    & .select-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }

  &--gray {
    background: $bg-input;
    color: $color-dark;

    &:hover {
      background: darken($bg-input, 5%);
    }
  }

  &--circle {
    justify-content: center;
    align-items: center;
    @include size(36px);
    padding: 11px;
  }

  &--modal {
    width: 90px;
  }

  &--block {
    width: 100%;
  }

  .button__loader {
    @include size(20px);
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
  }

  &--loading-children {
    visibility: hidden;
  }
}

.button__icon {
  @include size(14px);
  transition: $main-transition;

  &--bigger {
    @include size(18px);
  }

  &.plus {
    @include size(12px);
  }
}

.sso-btn {
  width: 100%;
  background-color: #4a4a4a;
  color: #fff;

  &:hover {
    background-color: #4a4a4a;
  }

  &-wrapper {
    margin-bottom: 24px;
  }
}
