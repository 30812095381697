.dashboard-page {
  .dashboard-table {
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
    flex-direction: row !important;
    background-color: #f9f9f9;
    padding: 20px 30px;

    &__max-scope-for-three {
      width: 50%;
      &-item {
        width: 25%;
      }
    }

    &__max-scope-for-four {
      width: 40%;
      &-item {
        width: 20%;
      }
    }

    &__block {
      width: calc(50% - 14px);
      height: max-content;
      border-radius: 32px;
      border: 1px solid $border-color;
      padding: 20px 0 0;
      background-color: #fff;
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 20px;
        button {
          padding: 10px;
        }
      }
    }

    &__block-title {
      font-size: 18px;
      font-weight: 700;
    }

    .dashboard-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      caption {
        padding-bottom: 20px;
      }
      .table__head {
        border-top: none;
      }
      .table__row-head {
        background-color: #4a4a4a;
        .table__head-column {
          &:first-child {
            border-top-left-radius: 20px;
            padding-left: 24px;
          }
          &:last-child {
            border-top-right-radius: 20px;
          }
          color: #dbdbdb;
        }
      }
      .table__body {
        border: none;
        .table__row {
          &--opener {
            &:not(:last-child) {
              border-bottom: 1px solid #adadad;
            }
          }
          & .table__row-title {
            padding-left: 20px;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #e6e6e6;
          }
          td {
            border-bottom: none !important;
            &::before {
              height: 0;
            }
            &:not(:last-child) {
              border-right: 1px solid #e6e6e6;
            }
            .due-date {
              display: flex;
              flex-direction: column;
              .pay-date {
                font-size: 12px;
              }
              .overdue-days {
                color: #adadad;
                font-size: 12px;
                &.red {
                  color: #f0504b;
                }
              }
            }
          }
        }
      }
      & .table__row:last-child {
        font-weight: bold;
        & .table__row-title {
          padding-left: 24px;
          color: #adadad;
        }
      }
    }
  }
  .dashboard-chart {
    padding: 0 20px 20px;
  }
}
.dashboard-modal {
  .dashboard-draggable-container {
    position: relative;
  }
  .dashboard-modal-draggable {
    padding: 10px;
    gap: 10px;
    align-items: center;
  }

  .dashboard-drag {
    color: #808080;
    cursor: grab;
    &-grabbing {
      cursor: grabbing;
    }
  }
}
