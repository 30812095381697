$path-fonts:                                  '../assets/fonts/';

$breakpoints: ('xs': 319px, 'sm': 575px, 'md': 767px, 'lg': 991px, 'xl': 1199px);

@import './colors';

$font-family-roboto:                          "Roboto", sans-serif !default;
$main-font:                                   var(--body-font);
$title-font:                                  var(--titles-font);

$big-font-size:                               18px;
$middle-font-size:                            16px;
$main-font-size:                              14px;
$small-font-size:                             12px;
$xsmall-font-size:                            11px;
$xxsmall-font-size:                           10px;
$large-font-size:                             20px;

$border-radius:                               20px;
$border-radius-smaller:                       18px;
$border-radius-input:                         4px;
$table-border:                                1px solid #e6e6e6;
$white-input-border:                          1px solid $main-bg;

$header-box-shadow:                           0 10px 20px 0 rgba($gray, 0.05);
$modal-box-shadow:                            0 6px 20px 0 rgba($black, 0.1);
$menu-box-shadow:                             10px 0 20px 0 rgba($gray, 0.05);
$dropdown-box-shadow:                         0px 2px 15px 0px #00000040;

$main-transition:                             0.15s;
$middle-transition:                           0.3s;
$animation:                                   show 1 0.3s linear forwards;

$header-height:                               60px;

$height-input:                                44px;
$input-padding-right:                         12px;
$input-with-icon-pad-left:                    38px;
$input-with-control-pad-right:                26px;
$input-icon-left:                             12px;
$input-control-right:                         6px;

$form-grid-gap:                               10px;

$width-input-half:                            calc(50% - 5px);
$width-input-third:                           calc((100% - 20px)/3);
$width-input-fourth:                          calc(25% - 2.5px);
$width-input-sixth:                           16.95%;
$width-input-two-third:                       calc(#{#{$width-input-third}*2} + 10px);
$width-input-third-preview:                   34px;
$width-input-two-third-preview:               calc(100% - #{$width-input-third-preview} - 10px);
$width-input-five-sixth:                      calc(83.05% - 10px);
$width-input-near-third:                      36%;

$dropdown-top-position:                       calc(100% + 4px);

$width-cross:                                 12px;
$width-arrow-form-group:                      12px;

$scroll-width:                                5px;
$fg-wr-margin:                                24px;

$body-row-height:                             42px;
$padding-left-page-info-block:                30px;
$padding-top-page-info-block-with-border:     26px;
$body-rowspan-row-height:                     calc(#{$body-row-height} * 2);
$table-head-row-height:                        31px;