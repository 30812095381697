.modal__event {
  &.modal {
    position: absolute;
    max-height: calc(100vh - 10px);
    height: max-content;
    z-index: 11;
    background-color: unset;
    padding-top: 0;
    padding-bottom: 0;
    width: 400px;
    left: 0;
    top: 0;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .modal__body {
    height: 100%;
    max-height: calc(100vh - 10px);
    overflow: unset;
  }
  .page__profile-block-avatar {
    margin-right: 5px;
  }

  .modal__content {
    @include scroll-styles;
    overflow-y: auto;
    max-height: calc(100vh - 156px);
    padding-right: 20px;
    padding-bottom: 2px;

    .table__data-wrapper {
      flex-wrap: wrap;
    }
  }

  .modal__title {
    max-width: 190px;
    overflow-wrap: break-word;
  }

  &__title {
    position: relative;
    white-space: break-spaces;

    &::after {
      content: '';
      position: absolute;
      transform: translateY(50%);
      right: -26px;
      background-color: var(--event-circle-color);
      border-radius: 50%;
      @include size(16px);
    }
  }

  &__details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  &__reminders-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__date-time-info,
  &__repeat-remind-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    line-height: 16px;
    margin-bottom: 10px;
  }

  &__date {
    font-weight: bold;
    white-space: nowrap;
    margin-right: 12px;
  }

  &__time {
    white-space: nowrap;
  }

  &__label {
    font-size: $small-font-size;
    font-weight: bold;
    color: rgba($color-dark, 0.5);
    text-transform: uppercase;
    text-align: right;
  }

  &__repeat {
    margin-right: 11px;
  }

  .modal__info-row {
    @include info;
    margin-bottom: 20px;
    overflow: hidden;

    &--participants &__avatar {
      margin-right: 10px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    &--questionnaires &__value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &--half {
      width: calc((100% - 20px) / 2);
    }
  }

  .modal__info-row__name,
  .modal__grid__item-name {
    @include info-name;
    margin-bottom: 4px;
  }

  .modal__info-row__value,
  .modal__grid__item-value {
    @include info-value;

    &--red {
      color: $color-red;
    }
  }

  .modal__info-row__avatar {
    @include info-value-hr-avatar;
    width: 18px;
    height: 18px;
    margin-right: 6px;

    &--all {
      border-radius: unset;
      object-fit: unset;
    }

    &--no-img {
      @include avatar-without-img;
    }
  }

  .modal__info-row__quest-link {
    display: block;
    width: 100%;
    color: $color-red;
    margin-bottom: 4px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .modal__info-row__quest-icon {
    width: 12px;
    height: 12px;
    margin-left: 6px;
    opacity: 1;
    fill: $color-red;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    border-top: $table-border;
    padding-top: 20px;
    margin-top: 30px;
  }

  &__status-value {
    font-size: $small-font-size;
    line-height: $small-font-size;
    color: rgba($color-dark, 0.6);
    margin-bottom: 4px;
  }

  &__status-responsible,
  &__status-date {
    line-height: 18px;
  }

  &__status-responsible {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.modal--event {
  .form__event-wrapper {
    max-height: calc(100vh - 280px);
  }
}
