.activity_page .page__panel-view {
  margin-right: 20px;
}

.activity-chart-table {
  .table-border {
    width: calc(100% / 12);
    height: 100%;
    border-right: 1px solid $border-color;

    &-wrapper {
      padding-left: 10px;
      display: flex;
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
    }
  }

  .activity__no-data {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    justify-content: center;
    color: rgba(74, 74, 74, 0.503742);
    padding-top: 12px;
  }

  .table__row {
    .table__data:not(.first-column) {
      padding-left: 0;
    }

    &.table__row-head {
      .table__head-column:nth-child(1) {
        width: 400px;
        border-right: 1px solid $border-color;
      }

      .table__head-column:nth-child(2) {
        position: relative;
        padding-left: 0;
      }
    }

    td:not(:last-child) {
      border-right: 1px solid $border-color;
    }

    &.table__sub-row .table__data:before {
      content: none;
    }
  }

  .work-chart {
    width: 100% !important;

    text {
      display: none;
    }
    svg g:nth-child(2) rect {
      stroke-width: 0;
    }

    div {
      overflow: visible !important;
    }

    div > svg {
      g:nth-child(4) {
        rect:first-child,
        rect:last-child {
          display: none;
        }
      }
    }
  }

  .activity__time-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    padding-left: 10px;
  }

  .activity__time {
    display: flex;
    align-items: center;
    padding-left: 10px;
    position: relative;
    width: calc(100% / 12);
    height: 100%;
    border-right: 1px solid $border-color;

    &:first-child {
      padding-left: 0;
    }
  }
}

.work-chart {
  table {
    display: none;
  }

  div {
    @include scroll-styles;
  }

  .tooltip {
    font-size: 14px;
    padding-bottom: 20px;

    .bold-text {
      font-weight: bold;
    }

    &__header {
      padding: 15px;
      font-weight: bold;
      border-bottom: 1px $table-hierarchical-border-devider solid;
    }
    &__info {
      padding: 15px;
    }
    &__user {
      width: max-content;
      margin-bottom: 15px;
    }
  }

  &.custom-node {
    text {
      display: none;
    }
  }

  svg {
    g:nth-child(2) {
      rect {
        fill: transparent;
        stroke-width: 1;
        stroke: $border-color;
      }
      rect:last-child {
        stroke: #fff;
      }
      path {
        stroke-width: 0;
      }
    }

    g:nth-child(3) {
      display: none;
    }

    g:nth-child(5) {
      text {
        display: none;
      }
    }
    g:nth-child(7) {
      text {
        display: none;
      }
      rect {
        stroke-width: 1px;
      }
    }
    text[font-size='13'] {
      display: none;
    }
  }
}

.page__scrollable-table-wrapper__inner.activity-table-wrapper {
  position: unset;
  max-height: unset;

  .activity-table {
    border-top: 1px solid $border-color;
    border-collapse: separate;

    tbody .first-column {
      border-right-width: 2px;
    }
  }

  .table__head .table__row:nth-child(1) .table__head-column:not(:first-child):not(.total-header) {
    width: calc(var(--column-width) * 4);
    border-bottom: 1px solid $border-color;
  }

  .table__head-column.total-header {
    width: calc(var(--column-width) * 4);
    right: 0;
    border-bottom: 1px solid $border-color;
    border-left-width: 2px;
    text-align: center;
  }

  .table__head-column {
    min-width: 80px;
    font-weight: bold;
  }

  .sticky {
    position: sticky;
    background: #fff;
  }

  .total-submited {
    right: 0;
  }

  .total-tracked {
    right: var(--column-width);
  }

  .total-active {
    right: calc(var(--column-width) * 2);
  }

  .sticky.total-logged {
    right: calc(var(--column-width) * 3);
    border-left-width: 2px;
  }

  .table--striped.activity-table {
    .table__head .table__row .table__head-column:first-child,
    .table__row .table__data:first-child {
      position: sticky;
      min-width: 300px;
      width: 300px;
      left: 0;
      background: #fff;
      z-index: 1;
      border-right: 2px solid $border-color;
    }

    .table__row.even {
      .first-column,
      .sticky {
        background-color: $bg-table-row;
      }
    }

    .table__row .table__data:nth-child(2),
    .table__row-head .table__head-column:nth-child(2) {
      border-left: none;
    }
  }
}

.page__scrollable-table-wrapper.page_activity-table_view {
  .horizontal-scroll-wrapper {
    width: calc(100% - 300px - var(--column-width) * 4);
  }

  .horizontal-scroll {
    width: calc(var(--table-width) - 300px - var(--column-width) * 4);
  }

  .activity-table-wrapper {
    border-right: 1px solid $border-color;
  }
}

.activity_page .title-block {
  display: flex;
  align-items: baseline;

  .task-tracker-button {
    font-size: 16px;
    cursor: pointer;
    color: $global-color;
    margin-left: 8px;
    padding: 10px 16px 10px 12px;
    border-radius: 18px;

    &:hover {
      text-decoration: underline;
    }
  }
}
