.client-modal {
  .form__inputs-subwrapper .form__inputs-subwrapper {
    margin-bottom: 0;
  }

  .form__inputs-subwrapper .form__input-block {
    width: 87px;

    .select__placeholder {
      margin-left: 0;
    }

    &.date-wrapper .form__input-block {
      width: 125px;
    }
  }

  .rate-input {
    label {
      font-size: 13px;
    }
  }
}
