$fg-wr-margin: 24px;
$width-cross: 12px;
$form-new-comp-width: 828px;
$width-move: 10px;
$width-index-form-group: 24px;
$width-btns-form-group: 24px;

.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  *:nth-last-child(1) {
    margin-bottom: 0;
  }

  &__input-wrapper {
    position: relative;
  }

  &--page {
    max-width: $form-new-comp-width;

    & > .form__inputs-wrapper {
      border-bottom: $table-border;
      padding-bottom: 40px;
      margin-bottom: 30px;

      &.no-margin {
        margin: 0;
      }

      &.no-border {
        border: none;
      }
    }

    .form__group-wrapper .form__btn-add-group {
      margin-left: calc(#{$width-move} + 10px);
    }

    .form__btn-add-group {
      background: $bg-input;
      border-radius: $border-radius-input;
      line-height: 16px;
      font-weight: bold;
      padding: 10px 12px;
      margin-bottom: $fg-wr-margin;
      cursor: pointer;
    }

    .form__group-wrapper {
      margin-bottom: 12px;
    }

    .form__group-wrapper .form__group-wrapper {
      margin-bottom: 10px;
    }

    .form__inputs-subwrapper {
      margin-bottom: $fg-wr-margin;
    }

    .form__input-block {
      margin-bottom: 10px;

      &.margin-bottom-24 {
        margin-bottom: 24px;
      }
    }
  }

  .form__error {
    padding-bottom: 10px;
  }
}

.form__inputs-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  &.form__event-wrapper {
    display: block;
    padding-bottom: 20px;
    & .form__input-date-wrapper {
      width: max-content;
    }
  }

  *:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.form__btn-icon {
  width: 100%;
  height: $width-cross;
  fill: rgba($color-dark, 0.5);
  transition: $main-transition;

  &--move {
    height: 14px;
  }

  &--open {
    width: $width-arrow-form-group;
    height: 8px;
  }
}

.form__group {
  display: flex;
  flex-direction: column;
  margin-bottom: $fg-wr-margin;

  &.questinnaires-wrapper {
    margin-top: 24px;
  }

  &--dropdown {
    &.open {
      .form__group__visible + .form__group {
        margin-top: $fg-wr-margin;
      }

      .form__group.hidden {
        display: flex;
      }

      .form__btn-icon--open {
        transform: rotate(0);
      }
    }

    .form__group.hidden {
      display: none;
    }

    .form__group-wrapper,
    .form__group-wrapper .form__group-wrapper {
      align-items: flex-start;
      margin-bottom: $fg-wr-margin;
    }

    .form__btn-move-inputs,
    .form__group__open-btn {
      width: $width-btns-form-group;
    }

    .form__grid-wrapper .form__btn-clean-inputs {
      padding-top: 5px;
    }

    .form__btn-icon--open {
      transform: rotate(-90deg);
    }

    .form__btn-icon--move {
      width: $width-move;
    }

    .form__btn-move-inputs {
      padding: 5px 0 0 4px;
    }
  }
}
.form__btn-clean-inputs,
.form__btn-move-inputs,
.form__group__open-btn {
  display: flex;
  cursor: pointer;
  height: $height-input;

  &:hover {
    .form__btn-icon {
      fill: $color-dark;
    }
  }
}

.form__btn-move-inputs {
  width: 20px;
}

.form__btn-move-inputs {
  padding: 16px 10px 16px 0;
  cursor: move;

  & + .form__inputs-wrapper {
    width: calc(100% - #{$width-cross} - 10px - #{$width-move} - 10px);
  }
}

.form__grid-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 10px;

  &--before-hours {
    padding-right: 22px;
    padding-bottom: 10px;
  }

  &.username {
    margin-bottom: 14px;
  }
}

.form__group-wrapper {
  display: flex;
  margin-bottom: 10px;

  &--bordered {
    padding: 14px;
    border: $table-border;
    border-radius: $border-radius-input;
  }

  &--bordered-top-bottom {
    padding-top: $fg-wr-margin;
    padding-bottom: 40px;
    border-top: $table-border;
    border-bottom: $table-border;
    border-radius: $border-radius-input;
  }

  &--bordered-top {
    padding-top: $fg-wr-margin;
    padding-bottom: 40px;
    border-top: $table-border;
    border-radius: $border-radius-input;
  }
  &--with-gap {
    gap: 5px;
  }
}

.form__group .form__inputs-wrapper {
  margin-bottom: 0;
}

.form__group-wrapper > .form__inputs-wrapper {
  width: calc(100% - #{$width-cross} - 10px);
}

.form__group .form__group-wrapper .form__inputs-subwrapper {
  margin-bottom: 10px;
  align-items: flex-start;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  &.form__group__visible {
    margin-bottom: 0;
  }
}

.form__inputs-subwrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  &.align-start {
    align-items: flex-start;
  }
}

.form__inputs-subwrapper .form__input-block {
  margin-right: 10px;
  margin-bottom: 0;

  &:nth-last-child(1) {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.form__input,
.form__textarea {
  width: 100%;
  @include text-styles;
  padding: 22px $input-padding-right 4px;
  @include input-styles;

  &:focus + .form__label,
  &.not-empty + .form__label {
    @include input-label-shrink--font;
  }

  &.recognition-icon,
  &.menu-link-icon {
    background-color: #ffffff;
  }
  &.event-textarea {
    min-height: 92px;
  }
  &.menu-link-icon {
    padding-top: 9px;
    padding-left: 0;
  }

  &.recognition-icon {
    padding-left: 0;
    cursor: pointer;
  }
}

.form__input {
  height: $height-input;

  &--pass {
    padding-right: 36px;
  }

  &--third {
    width: calc((100% - 20px) / 3);
  }

  &--two-third {
    width: calc(calc((100% - 20px) / 3) * 2 + 10px);
  }

  &--semibold {
    font-weight: 500;
  }

  &--bold {
    font-weight: bold;
  }

  &--with-icon {
    padding-left: $input-with-icon-pad-left;

    & + .form__label {
      @include input-label-icon-shrink;
    }
  }

  &--with-control {
    padding-right: $input-with-control-pad-right;
  }

  &--no-label {
    display: flex;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 12px;
  }

  &:focus + .form__label,
  &.not-empty + .form__label {
    @include input-label-shrink--offset;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &.disabled {
    opacity: 0.5;

    & + .form__label {
      opacity: 0.7;
    }
  }
}

.form__textarea {
  min-height: $height-input;
  height: auto;
  max-height: calc(#{$height-input * 2 + $form-grid-gap});
  line-height: 18px;
  transition: $main-transition;
  overflow-y: auto;
  @include scroll-styles;
  resize: none;
  border-radius: unset !important;
  background-color: transparent !important;

  &--big {
    height: calc(2 * #{$height-input});
  }

  & + .form__label {
    background-color: $bg-input;
    padding-bottom: 2px;
  }

  &:focus + .form__label {
    background: $bg-input-focused;
  }

  &:focus + .form__label,
  &.not-empty + .form__label {
    top: 0;
    padding-top: 8px;
  }

  &--white {
    & + .form__label {
      background: $bg-input-white;
    }

    &:focus + .form__label {
      background: $bg-input-white-focused;
    }
  }
}

.textarea-wrapper-block {
  background-color: $bg-input;
  border-radius: 4px 4px 0 0;

  .form__label {
    border-radius: 4px 4px 0 0;
  }

  &:has(.form__textarea:not(.pollInput):focus) {
    background-color: $bg-input-focused !important;
  }
}

.form__input-block {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  @include different-values-of-width;

  &.hidden {
    display: none;
  }

  &.flex {
    display: flex;
  }

  &.form__input-date {
    width: 134px;
  }

  &.align-center {
    display: flex;
    height: $height-input;
    align-items: center;
  }

  &--new-assessment {
    width: 91px;

    .form__input {
      height: 36px;
    }
  }

  .form__input-block-input-wrapper {
    position: relative;
  }
}

.form__input-block.event-circle {
  padding-right: 0;

  &::after {
    @include size(24px);
    right: 10px;
  }
}

.form__input-block.event-circle .form__input {
  padding-right: 34px;
}

.form__label {
  position: absolute;
  font-family: $main-font;
  font-size: $main-font-size;
  line-height: $main-font-size;
  width: calc(100% - #{$input-padding-right});
  color: rgba($color-dark, 0.6);
  top: 16px;
  left: 0;
  padding-left: 12px;
  transition: 0.2s;
  cursor: auto;

  &--bold {
    font-weight: bold;
  }

  &.--error {
    color: $color-red;
  }

  &.--shrink {
    @include input-label-shrink--font;
    @include input-label-shrink--offset;
  }
}

.form__input-icon,
.form__input-control {
  @include size(16px);
  @include position(absolute, 50% null null $input-icon-left);
  transform: translateY(-50%);
  color: $color-dark;
}

.form__icon-open-select {
  position: absolute;
  width: 16px;
  height: 16px;
  @include icon-center-y;
  right: 10px;
  fill: $color-dark;
}

.form__input-control {
  left: auto;
  right: $input-control-right;
  cursor: pointer;
  display: none;
  opacity: 0.5;
  z-index: 2;

  &:hover {
    opacity: 1;
  }

  @at-root .form__input-block:hover .form__input-control {
    display: block;
  }
}

.form__error {
  color: $color-red;
  padding-top: 5px;
  font-size: $small-font-size;
  line-height: $small-font-size;
  display: flex;

  svg {
    min-width: 12px;
    min-height: 12px;
    max-width: 12px;
    max-height: 12px;
    position: relative;
    top: -1px;
    left: 0;
    transform: none;
    margin-right: 5px;
  }
}

.form__req-error {
  color: $color-red;
  padding: 0 12px 10px;
}

.form__buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 16px;

  &.display-center {
    justify-content: center;
  }
  & .half--button {
    flex: 1;
  }
}

.form__btn-add-group {
  display: flex;
  align-items: center;
  align-self: flex-start;
  border-radius: $border-radius-input !important;
  width: auto !important;
  color: $color-dark;
  font-weight: bold;
}

.form__icon-btn-add {
  @include size(12px);
  margin-right: 4px;
}

.form__dropdown-wrapper {
  height: $height-input;
  display: flex;
  align-items: center;

  .dropdown {
    width: fit-content;
  }
}

.form__btn-clean-inputs,
.form__btn-move-inputs,
.form__group__open-btn {
  display: flex;
  cursor: pointer;
  height: $height-input;
  &:hover {
    .form__btn-icon {
      fill: $color-dark;
    }
  }
}

.form__btn-clean-inputs {
  display: flex;
  cursor: pointer;
  height: $height-input;
  color: $color-dark;
  opacity: 0.5;
  width: calc(#{$width-cross} + 10px);
  padding: 17px 0 17px 10px;

  &:hover {
    opacity: 1;
  }
}

.form__input__counter-btns {
  display: flex;
  flex-direction: column;
  width: 36px;
  height: 100%;
  min-height: 36px;
  @include position(absolute, 0 0 null null);

  .sort {
    @include size(16px);
    @include position(absolute, 50% 50% null null);
    transform: translate(50%, -50%);
  }
}

.form__input__counter {
  width: 100%;
  height: 50%;
  cursor: pointer;
  z-index: 1;
}

.form__event-time-wrapper {
  position: relative;
  margin-right: 10px;
  width: 134px;
  & .form__input-block.form__input-block--third {
    margin-right: 0;
  }
}

.form.form__eventType-wrapper {
  display: block;
  & .form__input-date-wrapper {
    width: max-content;
  }
}

.form__input-block.bonuses-ratio {
  max-width: 60px;
}

.form__inputs-subwrapper.bonuses-ratio-wrapper {
  justify-content: space-between;
}

.form__inputs-wrapper.new-bonus-wrapper .depends-of-experience {
  margin-bottom: 24px;
}

.modal .form__inputs-wrapper.edit-bonus-by-grades-wrapper {
  max-height: calc(100vh - 252px);
}

.form__group.bonuses__depends-on-exp {
  padding-left: 15px;
}

.label.form__checkbox-capitalazie-label {
  display: block;
  padding-left: 0;
  padding-right: 0;
  text-transform: capitalize;
  font-weight: normal;

  &.label-bold {
    font-weight: bold;
  }

  &.absence-checkbox {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-top: 0;
    padding-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form__group .form__inputs-bonuses-exp-value {
  display: flex;
  & > .form__input-block {
    margin-bottom: 0;

    &:first-child {
      margin-right: 24px;
    }
  }
  & .clear-restriction {
    color: $color-red;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.form__input-block.number-input .form__input__counter-btns {
  height: 46px;
}

.form__inputs-subwrapper.bonus-active-wrapper {
  width: 80px;
}

.sign-in-form {
  .form__input-block {
    margin-bottom: 24px;
  }

  .form__input-block {
    .sign-in__username,
    .sign-in__password {
      padding: 10px 14px 10px 14px;
      height: 36px;
      border-radius: 18px;
    }
  }
}

.form.custom-menu-link {
  & .form__inputs-subwrapper {
    margin-bottom: 2px;
  }
  & .icon-input-title {
    color: #828282;
    font-size: 14px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset;
  box-shadow: 0 0 0 30px #f5f5f5 inset;
}

.form__month-select-separator {
  display: flex;
  height: 44px;
  align-items: center;
  margin-right: 10px;
}
