.user-hours-wrapper {
  margin-bottom: 12px;
}

.user-hours-wrapper-flex {
  display: flex;
}

.user-hours-block {
  margin-right: 15px;
  &__without-margin {
    margin: 0 !important;
  }

  .global-color {
    color: $global-color;
  }
}

.user-hours-title {
  font-size: 12px;
  color: $color-90;
  margin-bottom: 6px;
}

.planning-pencil {
  margin: 0 6px 0 0;
  padding: 0 9px;
  cursor: pointer;
  color: $global-color;
  display: flex;
  align-items: center;
}

.page__panel__back-plan-info {
  display: flex;

  .user-hours-block {
    margin-right: 16px;
  }
}

.user-plan-page {
  .page__profile-panel__label-options {
    align-items: center;
  }

  .plan-month {
    margin-right: 20px;
    font-weight: 700;
  }

  .page__title {
    margin-bottom: 20px;
  }
}

.new-plan-modal {
  .currency-select {
    min-width: 69px;
    width: 69px;
    .select__value-container {
      padding-right: 20px !important;
    }
  }

  .month-input {
    width: 201px;
  }

  .currency-rate-input {
    width: 350px;
  }

  .rate-input {
    label {
      width: 100%;
      padding-left: 10px;
      font-size: 13px;
    }
  }
}

.employees-plan-table {
  .table__head-column:not(:first-child) {
    text-align: right;
  }

  .table__head-column--dropdown,
  .table__actions-block {
    padding-left: 50px;
  }
}

.plans-table,
.employees-plan-table {
  .global-color {
    color: $global-color;
  }

  .table__head .table__row {
    &:first-child {
      border-bottom: none;
      height: 30px;
    }
    .two-column {
      border-bottom: 1px solid $border-color;
      text-align: center;
    }
  }
  .table__head-column:not(:last-child) {
    border-right: 1px solid $border-color;
  }
  .column-name {
    margin-top: -20px;
  }

  .high-display {
    position: absolute;
    top: -22px;
    left: 75%;
    transform: translateX(-50%);
  }
}

.clone-plan__confirm-modal {
  table {
    width: max-content;

    td {
      padding: 5px;
      vertical-align: middle;
    }
    .original-value {
      text-decoration: line-through;
    }
    .new-value {
      color: red;
    }
  }
}

.edit-user-plan__modal,
.add-user-plan__modal {
  .other-project-wrapper {
    padding: 15px 0;
  }

  .form__inputs-subwrapper:last-child {
    padding-top: 14px;
  }

  .other-project-name {
    display: flex;
    height: 44px;
    align-items: center;
    font-weight: 600;
  }

  .modal-finalized-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    padding-top: 24px;
    width: 100%;
    border-top: 1px solid $border-color;
  }

  .available-label {
    font-weight: 600;

    &.hidden-label {
      visibility: hidden;
    }
  }
}
