.users-hours-absences-wrapper {
  margin-left: 838px;

  .table__head {
    border-top: 0;
  }

  .table__head-column {
    border-right: 1px solid $border-color;
    position: relative;
    border-top: 1px solid $border-color;
  }

  .table__row {
    position: static;
  }

  .table__data,
  .table__head-column,
  .header_row {
    &:first-child() {
      position: absolute;
      left: 0;
      width: 260px;
      z-index: 1;

      &:before {
        width: 260px;
      }
    }

    &:nth-child(2) {
      position: absolute;
      left: 259px;
      width: 200px;

      .table__data-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:nth-child(3) {
      position: absolute;
      left: 459px;
      width: 80px;
    }

    &:nth-child(4) {
      position: absolute;
      left: 539px;
      width: 64px;
    }

    &:nth-child(5) {
      position: absolute;
      left: 603px;
      width: 85px;
    }

    &:nth-child(6) {
      position: absolute;
      left: 688px;
      width: 76px;
    }

    &:nth-child(7) {
      position: absolute;
      left: 764px;
      width: 74px;
    }
  }

  .table__head-column:not(.header_row):nth-child(-n + 7) {
    border-top: none;
    height: 66px;
    top: 0;
    padding-top: 30px;
  }

  .table__data-holiday {
    background: $bg-input;
  }

  .table__data {
    border-right: 1px solid $border-color;
    min-width: 56px;
    height: 43px;
    padding: 0;

    .table__data-value {
      padding: 15px 10px 12px 10px;
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }

  .event_column {
    position: absolute;
    right: 0;
    width: 50px;
    min-width: 50px;
    border-left: 1px solid $border-color;
    &:not(:last-child) {
      border-right: none;
    }
  }

  .header_row {
    height: 30px;
  }
  .header_row-event {
    position: absolute;
    right: 0;
    text-align: center;
    color: $color-dark;
    font-weight: bold;
    border-left: 1px solid $border-color;
    border-right: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table__row:last-child {
    td:nth-child(-n + 7) {
      border-bottom: 1px solid $border-color;
    }
    &.show-last-devider .first-column {
      border-bottom: 1px solid $table-hierarchical-border-devider;
    }

    font-weight: bold;
  }

  .month-year-display {
    position: absolute;
    top: -22px;
    left: 6px;
    width: 60px;
    color: #4a4a4a;
    background-color: #fff;

    &.right {
      left: unset;
      right: 6px;
      text-align: end;
    }
  }

  th:nth-child(9) {
    .month-year-display {
      left: 25px;
    }
  }
}

.event-types-note {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 20px;

  div:not(:last-child) {
    margin-right: 20px;
  }
}
